import { Component, OnInit } from '@angular/core';
import { Promocion } from './promocion';

@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.component.html',
  styleUrls: ['./promociones.component.css']
})
export class PromocionesComponent implements OnInit {

  promociones: Promocion[]= [
    {nombre: 'Promo 1', descripcion: 'Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve'},
    {nombre: 'Promo 2', descripcion: 'Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve'},
    {nombre: 'Promo 3', descripcion: 'Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve'},
    {nombre: 'Promo 4', descripcion: 'Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve'},
    {nombre: 'Promo 5', descripcion: 'Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve'},
    {nombre: 'Promo 6', descripcion: 'Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve'},
    {nombre: 'Promo 7', descripcion: 'Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve'},
    {nombre: 'Promo 8', descripcion: 'Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve'},
    {nombre: 'Promo 9', descripcion: 'Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve'}
  ];

  index= 0;

  constructor() { }

  ngOnInit() {
  }

}
