import { Injectable } from '@angular/core';
import { Observable,interval, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import{ environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded',
  })
};
@Injectable({
  providedIn: 'root'
})

export class ServiceService {
  constructor(private http: HttpClient) { }

  contactService(data: FormGroup): Observable<any> {
    console.log(data);
    return this.http.post<any>(environment.apiUrl + "/mail/contact",data);
  }
  downloadService(data:FormGroup): Observable<any> {
    // return this.http.post<any>('http://wabiit.com:3000/api/contact',data)
    return this.http.get<any>('https://jsonplaceholder.typicode.com/todos/1');
  }
}
