import { Component, OnInit } from '@angular/core';
import { Sucursal } from './sucursal';
import { ServiceService } from '../data/services/service.service'
import { ToastrService } from 'ngx-toastr';
import {
    ReactiveFormsModule,
    FormsModule,
    FormGroup,
    FormControl,
    Validators,
    FormBuilder,
} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';


@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
  all = true;
  contactForm: FormGroup;
  name: FormControl;
  email: FormControl;
  phone: FormControl;
  text: FormControl;
  map_location_name = "Morelia (Matriz)";
  google_map = "https://www.google.com/maps/embed/v1/place?q=";
  google_map2 = "https://www.google.com/maps/embed?pb=";
  map_place = "Adolfo%20Cano%20N%C2%B0%2096%20Col.Chapultepec%20Norte%20Morelia%2C%20Michoac%C3%A1n&key=AIzaSyAKII7Zcjdt8fuUjrdcAeLBzXQgUUtt_O8";




  sucursales : Sucursal[]= [
    { nombre: "Morelia (Matriz)", calle: "Adolfo Cano N° 96", colonia: "Col.Chapultepec Norte", ciudad: "Morelia, Michoacán", num1: "(443) 340 1169", horario: "9am a 2pm; 4pm a 7pm", color: "00aa87", map: "Adolfo%20Cano%20N%C2%B0%2096%20Col.Chapultepec%20Norte%20Morelia%2C%20Michoac%C3%A1n&key=AIzaSyAKII7Zcjdt8fuUjrdcAeLBzXQgUUtt_O8", map2:"!1m18!1m12!1m3!1d3756.3821654756935!2d-101.17981948599642!3d19.69634718673505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0e0990eb8033%3A0x792b091df75233e2!2s3D%20Evolution%20Express%20Odonto!5e0!3m2!1ses!2smx!4v1583213921388!5m2!1ses!2smx"},
    { nombre: "Morelia (Sucursal)", calle: "Av. Acueducto N° 604", colonia: "Col. Chapultepec Norte", ciudad: "Morelia, Michoacán", num1: "(443) 147 5348", horario: "9am a 2pm; 4pm a 7pm", color: "006455", map: "Av.%20Acueducto%20N%C2%B0%20604%20Col.%20Chapultepec%20Norte%20Morelia%2C%20Michoac%C3%A1n&key=AIzaSyAKII7Zcjdt8fuUjrdcAeLBzXQgUUtt_O8", map2: "!1m18!1m12!1m3!1d3756.333388470039!2d-101.17971988599636!3d19.698425386733824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0e09976dd7dd%3A0x79ad5c998c6c6336!2s3D%20Evolution%20Dental%20Acueducto!5e0!3m2!1ses!2smx!4v1583214435141!5m2!1ses!2smx"},
    { nombre: "Zitácuaro", calle: "Leandro Valle Sur N° 1-A", colonia: "Col.Melchor Ocampo", ciudad: "Zitácuaro, Michoacán, CP:61506", num1: "(715) 132 8182", horario: "9am a 2pm; 4pm a 7pm", color: "00aa87", map: "Leandro%20Valle%20Sur%20N%C2%B0%201-A%20Col.Melchor%20Ocampo%20Zit%C3%A1cuaro%2C%20Michoac%C3%A1n%2C%20CP%3A61506&key=AIzaSyAKII7Zcjdt8fuUjrdcAeLBzXQgUUtt_O8", map2: "!1m18!1m12!1m3!1d3762.43040685297!2d-100.35750148600192!3d19.43700138688177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2a5a7a5e7ed3f%3A0x7d3a91e23fddc2d!2s3D%20Evolution%20Dental%20Zitacuaro!5e0!3m2!1ses!2smx!4v1583214514573!5m2!1ses!2smx"},
    { nombre: "Uruapan", calle: "Plaza Atara, Av.Latinoamerica N° 20 Int.7", colonia: "Col. La Magdalena", ciudad: "Uruapan,Michoacán", num1: "(452) 148 9533", horario: "9am a 2pm; 4pm a 7pm", color: "006455", map:"Plaza%20Atara%2C%20Av.Latinoamerica%20N%C2%B0%2020%20Int.7%20Col.%20La%20Magdalena%20Uruapan%2CMichoac%C3%A1n&key=AIzaSyAKII7Zcjdt8fuUjrdcAeLBzXQgUUtt_O8", map2: "!1m18!1m12!1m3!1d3763.124507040112!2d-102.05756258600259!3d19.407025686898887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842de2639615f5f1%3A0x96435dddb3f5651f!2s3D%20Evolution%20Dental%20Uruapan!5e0!3m2!1ses!2smx!4v1583214566992!5m2!1ses!2smx"},
    { nombre: "Zamora", calle: "Av. Virrey de Mendoza N° 630-3", colonia: "Col. Jardinadas", ciudad: "Zamora, Michoacán, CP: 59680", num1: "(351) 515 4221", horario: "9am a 2pm; 4pm a 7pm", color: "00aa87", map:"Av.%20Virrey%20de%20Mendoza%20N%C2%B0%20630-3%20Col.%20Jardinadas%20Zamora%2C%20Michoac%C3%A1n%2C%20CP%3A%2059680&key=AIzaSyAKII7Zcjdt8fuUjrdcAeLBzXQgUUtt_O8", map2: "!1m18!1m12!1m3!1d3749.7837676088852!2d-102.29056638599037!3d19.975593886578196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842e89529be729df%3A0x3e99ada06c0ee328!2s3D%20Evolution%20Dental%20Zamora!5e0!3m2!1ses!2smx!4v1583214619678!5m2!1ses!2smx"},
    { nombre: "León", calle: "Av. Universidad N° 415 Local 1-A", colonia: "Col. Lomas del Sur", ciudad: "León, Guanajuato", num1: "(477) 148 2069", horario: "9am a 2pm; 4pm a 7pm", color: "006455", map: "Av.%20Universidad%20N%C2%B0%20415%20Local%201-A%20Col.%20Lomas%20del%20Sur%20Le%C3%B3n%2C%20Guanajuato&key=AIzaSyAKII7Zcjdt8fuUjrdcAeLBzXQgUUtt_O8", map2: "!1m18!1m12!1m3!1d3721.0547762919564!2d-101.71240628596398!3d21.150218285932734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842d0e0985f3b3bb%3A0xbb6ae915cba5f182!2s3D%20Evolution%20Dental%20Leon!5e0!3m2!1ses!2smx!4v1583214664245!5m2!1ses!2smx"},
    { nombre: "CD Hidalgo", calle: "Av. Morelos Poniente", colonia: "Col. Centro", ciudad: "CD Hidalgo, Michoacán", num1: "(786) 114 0675", horario: "9am a 2pm; 4pm a 7pm", color: "00aa87", map: "Av.%20Morelos%20Poniente%20Col.%20Centro%20CD%20Hidalgo%2C%20Michoac%C3%A1n&key=AIzaSyAKII7Zcjdt8fuUjrdcAeLBzXQgUUtt_O8", map2: "!1m18!1m12!1m3!1d3756.5658950655!2d-100.55938348599655!3d19.688517286739437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2cb07a66df7f1%3A0x262461abbe6e60ad!2s3D%20Evolution%20Dental%20Cd%20Hgo!5e0!3m2!1ses!2smx"},
    { nombre: "Querétaro", calle: "Av. A. Tlacote N° 3-A", colonia: "Fracc. Santiago", ciudad: "Santiago de Querétaro, Querétaro", num1: "(442) 404 7609", horario: "9am a 2pm; 4pm a 7pm", color: "006455", map: "Av.%20A.%20Tlacote%20N%C2%B0%203-A%20Fracc.%20Santiago%20Santiago%20de%20Quer%C3%A9taro%2C%20Quer%C3%A9taro&key=AIzaSyAKII7Zcjdt8fuUjrdcAeLBzXQgUUtt_O8", map2: "!1m18!1m12!1m3!1d3735.028248007459!2d-100.41669568597678!3d20.58690398623932!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d34535279630ab%3A0xad860cbe79ef8c7f!2s3D%20Evolution%20Dental%20Qro!5e0!3m2!1ses!2smx!4v1583214825584!5m2!1ses!2smx"}
  ];

  constructor(private service:ServiceService,private toastr: ToastrService) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.name = new FormControl('', [
      Validators.required,
      Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$')
    ]);
    this.email = new FormControl('', [
      Validators.required,
      Validators.pattern("[^ @]*@[^ @]*")
    ]);
    this.phone = new FormControl('', [
      Validators.required,
      Validators.pattern('^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$')
    ]);
    this.text = new FormControl('',Validators.required);
  }
  createForm() {
    this.contactForm = new FormGroup({
      name: this.name,
      email: this.email,
      phone: this.phone,
      text: this.text
    });
  }
  onSubmit() {
    if (this.contactForm.valid) {
      console.log(this.contactForm.value);
      this.service.contactService(this.contactForm.value).subscribe( data => {
        console.log(data);
      });
      this.toastr.success('Enviado');
      this.contactForm.reset();
   }


  }

  mapchange(sucursal:string, nombre: string){
    this.all = false;
    this.map_place = sucursal;
    this.map_location_name = nombre;
  }
}
