import { Injectable } from '@angular/core';

import {Http, ResponseContentType} from '@angular/http';
import {Observable} from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  baseurl: string = environment.apiUrl + "/api/files/";

  constructor(private http: Http) { }

getFile(filename: string): Observable<any> {
  return this.http.get(this.baseurl + filename, { responseType: ResponseContentType.Blob });
 }
  // getFile(filename: string): Observable<any> {
  //   console.log(this.baseurl + filename);
  //   return this.http.get(this.baseurl + filename);
  // }

}
