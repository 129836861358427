import { Component, OnInit, ViewChild } from '@angular/core';
import { View, ScheduleComponent, Timezone, PopupOpenEventArgs, ActionEventArgs, EventSettingsModel, RenderCellEventArgs } from '@syncfusion/ej2-angular-schedule';
import { HttpClient, HttpParams } from '@angular/common/http';

import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';

import { AppointmentsService } from '../services/appointments.service';
import { DataManager, ODataV4Adaptor, Query } from '@syncfusion/ej2-data';
import { loadCldr } from '@syncfusion/ej2-base';

import { environment } from '../../environments/environment';
import { StudyBundle, StudyType } from '../services/backendClasses';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { gregorian, numbers, timeZoneNames } from './localization';

declare var $: any;


loadCldr(gregorian, numbers, timeZoneNames);

@Component({
  selector: 'app-citas-td-ev',
  templateUrl: './citas-td-ev.component.html',
  styleUrls: ['./citas-td-ev.component.css']
})
export class CitasTdEvComponent implements OnInit {

  @ViewChild('scheduleObj', {static : true})
  public scheduleObj: ScheduleComponent;
  public setView: View = 'Month'
  
  
  private dataManager: DataManager = new DataManager({
    url: environment.apiUrl + '/appointments/schedule',
    adaptor: new ODataV4Adaptor,
    crossDomain: true
  });
  private dataQuery: Query = new Query();
  public eventSettings: EventSettingsModel = {
    dataSource : this.dataManager,
    query: this.dataQuery
  };
  
  currentDate : Date;
  timezone : Timezone;

  private _transformer = (node: StudyNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      length: node.length
    };
  }
  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level, node => node.expandable
  );
  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children
  );
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: FlatNode) => node.expandable;
  getLevel = (node: FlatNode) => node.level;

  checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);

  selectedStudies: StudyTypeQuantity[];

  startHour = 9;
  endHour = 19;
  hours = [];
  
  studyBundles: StudyBundle[];

  clientForm: FormGroup;
  name: FormControl;
  email: FormControl;
  phone: FormControl;

  showStudiesWarning: boolean;

  constructor(private http: HttpClient, private appService: AppointmentsService) {
    this.timezone = new Timezone();
  }

  ngOnInit() {
    this.name = new FormControl('', [
      Validators.required,
      Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$')
    ]);
    this.email = new FormControl('', [
      Validators.required,
      Validators.pattern("[^ @]*@[^ @]*")
    ]);
    this.phone = new FormControl('', [
      Validators.required,
      Validators.pattern('^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$')
    ]);
    this.clientForm = new FormGroup({
      name: this.name,
      email: this.email,
      phone: this.phone
    });

    this.currentDate = new Date();
    this.getClinics();
    this.getBundles();
    this.generateTree();

    for (let i = this.startHour; i < this.endHour; i++)
      this.hours.push(i);
      
    this.selectedStudies = [];
    this.checklistSelection.clear();
  }

  getBundles(){
    this.http.get<StudyBundle[]>(environment.apiUrl + "/studies/bundles").subscribe(res=>{
      this.studyBundles = res;
    });
  }

  toggleBundle(bundle: StudyBundle){
    if(this.checklistSelection.selected.length > 0){
      this.checklistSelection.clear();
      this.selectedStudies = [];
    }

    for (let i = 0; i < bundle.types.length; i++) {
      for (let j = 0; j < this.treeControl.dataNodes.length; j++) {
        const node = this.treeControl.dataNodes[j];
        if(node.id == bundle.types[i].id){
          this.todoLeafItemSelectionToggle(node);
          break;
        }
      }
    }
  }

  onPopupOpen(args: PopupOpenEventArgs): void {
    if (args.type === 'QuickInfo' || args.type === 'EventContainer')  {
        args.cancel = true;
    }
    if (args.type === 'Editor')  {
      this.startDate = new Date(args.data["StartTime"]);
      if(this.clinicID < 0){
        //Select a Clinic
        args.cancel = true;
      }else if(
        this.startDate.getMonth() < this.currentDate.getMonth()
        || this.startDate.getDate() < this.currentDate.getDate()
      ){
        //Select a date >= today
        args.cancel = true;
      }else{
        args.cancel = true;
        this.showOne();
        this.resetFields();
        this.showForm();
        this.openModal();
      }
    }
  }
  
  onActionBegin(args : ActionEventArgs): void{
    if (args.requestType === 'viewNavigate'){
      args.cancel = true;
    }
  }


  openModal(){
    $('#exampleModal').modal('show');
  }
  hideModal(){
    $('#exampleModal').modal('hide');
  }

  startTime: string;
  endTime: string;
  startDate: Date;
  endDate: Date;
  dateString: string;

  public clinicID: number = -1;
  clinics = [];

  resetFields(){

    this.name.setValue('');
    this.email.setValue('');
    this.phone.setValue('');

    this.selectedStudies = [];
    this.checklistSelection.clear();

    this.endDate = new Date(this.startDate);
    this.startTime = "9:00";
    this.showStudiesWarning = false;
    this.updateStartDate();
    this.updateEndDate();
  }

  getClinics(){
    this.http.get<Object[]>(environment.apiUrl + "/appointments/list").subscribe(res => {
      this.clinics = res;
      this.clinicID = res[0]["id"];
      this.onClinicChange();
    });
  }

  onClinicChange(){
    this.dataQuery.params[0] = {
      key: "Clinic",
      value: `${this.clinicID}`
    };
    this.scheduleObj.refreshEvents();
  }

  getClinicIMG(){
    return `clinic-img clinic-${this.clinicID}`;
  }

  getMonthCellText(date: Date): string {
    return '<img src= "https://ej2.syncfusion.com/demos/src/schedule/images/birthday.svg" />';
  }
  /*onRenderCell(args: RenderCellEventArgs): void {
    //monthDay
    //monthCells
    if (args.elementType == 'monthCells') {
      let ele: HTMLElement = createElement('div', {
        innerHTML: "<img src='https://ej2.syncfusion.com/demos/src/schedule/images/newyear.svg' />",
        className: 'templatewrap'
      });
      (args.element).appendChild(ele);
    }
  }*/

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.updateEndDate();

    if(this.checklistSelection.isSelected(node)){
      this.selectedStudies.push({
        id: node.id,
        type: node.name,
        quantity: 1,
        node: node
      });
    } else {
      for (let i = 0; i < this.selectedStudies.length; i++) {
        const study = this.selectedStudies[i];
          if(study.id == node.id){
            this.selectedStudies.splice(i, 1);
            break;
          }
      }
    }
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FlatNode): void {
    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: FlatNode): FlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  onFocusOutEvent(event: any, study: StudyTypeQuantity){
    if(study.quantity < 1){
      console.log(study.quantity);
      this.todoLeafItemSelectionToggle(study.node);
    }
  }

  generateTree() {
    this.dataSource.data = TREE_DATA;
    let studies: StudyNode[] = [];
    let tree: StudyNode[] = [];
    this.http.get<StudyType[]>(environment.apiUrl + "/studies/types").subscribe(res=>{
      studies = res;
      //parse the tree
      for (let j = 0; j < res.length; j++) {
        const type = res[j];

        if(type.id == type.parentID){// root node
          tree.push(type);
        }else{
          //look for parent
          for(let i=0; i<studies.length; i++){
            if (studies[i].id == type.parentID) {
              if(!studies[i].children){
                studies[i].children = [];
              }
              studies[i].children.push(type);
              break;
            }
          }
        }
        
      }
      
      this.dataSource.data = tree;
    });
  }

  showForm(){
    const accordion = document.getElementById("accordion");
    accordion.classList.remove("hidden");
    const confirmMsg = document.getElementById("confirm-msg");
    confirmMsg.classList.add("hidden");
    const payment = document.getElementById("payment");
    payment.classList.add("hidden");
  }
  showConfirmMsg(){
    const accordion = document.getElementById("accordion");
    accordion.classList.add("hidden");
    const confirmMsg = document.getElementById("confirm-msg");
    confirmMsg.classList.remove("hidden");
  }
  showPayment(){
    const confirmMsg = document.getElementById("confirm-msg");
    confirmMsg.classList.add("hidden");
    const payment = document.getElementById("payment");
    payment.classList.remove("hidden");
  }
  click3(){
    this.createAppointment();
    this.showConfirmMsg();
    this.showOne();
    //this.appointmentsPerDay[this.startDate.getDate()]++;
  }
  showOne(){
    $('#collapseOne').collapse('show');
  }
  showTwo(){
    $('#collapseTwo').collapse('show');
  }
  showThree(){
    this.generateDateString();
    
    this.showStudiesWarning = this.checklistSelection.isEmpty();
    if(!this.showStudiesWarning)
      $('#collapseThree').collapse('show');
  }

  updateStartDate(){
    const digits = this.startTime.split(":");
    this.startDate.setHours(Number.parseInt(digits[0]));
    this.startDate.setMinutes(Number.parseInt(digits[1]));
    this.updateEndDate();
    console.log(this.startDate);
  }

  updateEndDate(){
    if(this.checklistSelection.selected.length == 0){
      this.endTime = this.startTime;
      this.endDate.setHours(this.startDate.getHours());
      this.endDate.setMinutes(this.startDate.getMinutes());
      return;
    }

    let appLength = 0;
    for (let i = 0; i < this.checklistSelection.selected.length; i++) {
      const node = this.checklistSelection.selected[i];
      if(!node.expandable){
        appLength += node.length;
      }
    }
    const hours = appLength / 60;
    const mins = appLength % 60;
    
    this.endDate.setHours(this.startDate.getHours() + hours);
    this.endDate.setMinutes(this.startDate.getMinutes() + mins);

    this.endTime = this.endDate.getHours() + (this.endDate.getMinutes() < 10 ? ":0":":") + this.endDate.getMinutes();
  }

  generateDateString(){
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    const weekDays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sabado'];
    this.dateString = 
      weekDays[this.startDate.getDay()]
      + " " + this.startDate.getDate()
      + " de " + months[this.startDate.getMonth()];
  }

  createAppointment(){
    let studyTypes = [];
    this.selectedStudies.forEach(element => {
      studyTypes.push({
        type : {
          id: element.id
        },
        quantity: element.quantity
      });
    });

    const appointment = {
      client : {
        id : -1,
        name : this.name.value,
        phone : this.phone.value,
        email : this.email.value
      },
      place : {
        id: this.clinicID
      },
      start : this.timezone.removeLocalOffset(this.startDate),
      end : this.timezone.removeLocalOffset(this.endDate),
      status: "Pendiente"
    };

    this.appService.create(appointment, studyTypes);
  }

}

interface StudyNode {
  id: number;
  parentID: number;
  name: string;
  length: number;
  children?: StudyNode[];
}
const TREE_DATA: StudyNode[] = [
  {
    id: 1,
    parentID : 1,
    name: 'Radiografías Intraorales',
    length: 0,
    children: [
      {
        id: 2,
        parentID : 1,
        name: 'Periapical',
        length: 15
      },
      {
        id: 3,
        parentID : 1,
        name: 'Serie periapical completa',
        length: 15
      },
    ]
  }
]

interface FlatNode {
  id: number;
  name: string;
  length: number;
  expandable: boolean;
  level: number;
}

interface StudyTypeQuantity{
  id: number;
  type: string;
  quantity: number;
  node: FlatNode;
}
