import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productos-silva-ev',
  templateUrl: './productos-silva-ev.component.html',
  styleUrls: ['./productos-silva-ev.component.css']
})
export class ProductosSilvaEvComponent implements OnInit {

  productos : Producto[]=[
    {nombre : "Promo 1", borde : "00aa87",  descripcion : "Descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve", imagen : ""},
    {nombre : "Promo 1", borde : "c89137", descripcion : "Descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve", imagen : ""},
    {nombre : "Promo 1", borde : "00aa87", descripcion : "Descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve", imagen : ""},
    {nombre : "Promo 1", borde : "00aa87", descripcion : "Descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve", imagen : ""},
    {nombre : "Promo 1", borde : "c89137", descripcion : "Descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve", imagen : ""},
    {nombre : "Promo 1", borde : "00aa87", descripcion : "Descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve", imagen : ""}
  ];

  constructor() { }

  ngOnInit() {
  }

}
class Producto{
  nombre: string;
  borde: string;
  descripcion: string;
  imagen: string;
}
