import { Injectable } from '@angular/core';

import{ environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {

  constructor(private http: HttpClient) { }


  create(appointment: Object, studyTypes: Object[]){
    const body = {
      appointment: appointment,
      studyTypes: studyTypes
    };
    this.http.post(environment.apiUrl + "/appointments/create", body).subscribe(res=>{
      this.http.post(environment.apiUrl + "/mail/appointmentRequest", {appointment:appointment, id:res}).subscribe(res=>{
        console.log(res);
      });
    });
  }

  //Request para pagar una orden
  pay(token: string, order: any, discount: any){
    const body = {
      token: token,
      order: order,
      discount: discount
    };
    return this.http.post(environment.apiUrl + "/orders/pay", body);
  }

  //Request para consultar una orden
  getOrder(folio: number){
    return this.http.get(environment.apiUrl + "/orders/folio/"+folio);
  }

  //Request para consultar una orden
  getAppointment(id: number){
    return this.http.get(environment.apiUrl + "/appointments/get/"+id);
  }

  //Request para pedir un codigo de descuento
  getVoucher(code:number){
    return this.http.post(environment.apiUrl + "/vouchers/", {code:code});
  }

  //Request para enviar recibo por correo
  sendReceipt(payment:any, folio:string){
    return this.http.post(environment.apiUrl + "/mail/receipt/", {payment:payment, folio:folio});
  }
}
