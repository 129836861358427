import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio-silva-ev',
  templateUrl: './inicio-silva-ev.component.html',
  styleUrls: ['./inicio-silva-ev.component.css']
})
export class InicioSilvaEvComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
