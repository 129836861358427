import {
  Component,
  OnInit,
  Renderer,
  HostListener,
  Inject
} from "@angular/core";
import { Router,NavigationEnd } from '@angular/router'
import { filter } from 'rxjs/operators'
import { NavbarComponent } from './navbar/navbar.component'
declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private router: Router){
    const navEndEvents$= this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd)
    );
    navEndEvents$.subscribe((event:NavigationEnd) => {
      gtag('config', 'UA-154235971-1',{
        'page_path': event.urlAfterRedirects
      });
    })
  }
  /*@HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    if (window.pageYOffset > 50) {
      var element = document.getElementById("navbar-top");
      if (element) {
        element.classList.remove("navbar-transparent");
        element.classList.add("bg-navbarResponsive");
      }
    } else {
      var element = document.getElementById("navbar-top");
      if (element) {
        element.classList.add("navbar-transparent");
        element.classList.remove("bg-navbarResponsive");
      }
    }
    /*if(window.innerWidth < 991){
      element.classList.remove("navbar-transparent");
      element.classList.add("bg-navbarResponsive");
    }
  }*/
  ngOnInit() {
    //this.onWindowScroll(event);
  }
}
