import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(
    private router: Router
  ) { }
  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    if (window.pageYOffset > 50) {
      var element = document.getElementById("navbar-top");
      if (element) {
        element.classList.remove("navbar-transparent");
        element.classList.add("bg-navbarResponsive");
      }
    } else {
      var element = document.getElementById("navbar-top");
      if (element && !this.navbarOpen) {
        element.classList.add("navbar-transparent");
        element.classList.remove("bg-navbarResponsive");
      }
    }
  }

  ngOnInit() {
    this.onWindowScroll(event);
  }

  ngAfterViewInit() {
    console.log(this.carouselHeight);
  }

  navbarOpen = false;
  carouselHeight;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
    var element = document.getElementById("navbar-top");

    if(this.navbarOpen){
      element.classList.remove("navbar-transparent");
      element.classList.add("bg-navbarResponsive");
    }

  }
}
