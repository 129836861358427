import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio-lab-silva',
  templateUrl: './inicio-lab-silva.component.html',
  styleUrls: ['./inicio-lab-silva.component.css']
})
export class InicioLabSilvaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
