import {
  Component,
  OnInit,
  Renderer,
  HostListener,
  Inject
} from "@angular/core";
import { Evento } from "./evento";
@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})

export class ServiciosComponent implements OnInit {

  eventos : Evento[]= [
    {nombre: "Evento 1", descripcion: "Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve"},
    {nombre: "Evento 2", descripcion: "Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve"},
    {nombre: "Evento 3", descripcion: "Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve"},
    {nombre: "Evento 1", descripcion: "Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve"},
    {nombre: "Evento 2", descripcion: "Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve"},
    {nombre: "Evento 3", descripcion: "Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve"},
    {nombre: "Evento 1", descripcion: "Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve"},
    {nombre: "Evento 2", descripcion: "Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve"},
    {nombre: "Evento 3", descripcion: "Descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve descripción breve"}
  ];

  constructor() { }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    if (window.pageYOffset > 50) {
      var element = document.getElementById("navbar-top");
      if (element) {
        element.classList.remove("navbar-transparent");
        element.classList.add("white-bg");
      }
    } else {
      var element = document.getElementById("navbar-top");
      if (element) {
        element.classList.add("navbar-transparent");
        element.classList.remove("white-bg");
      }
    }
  }

  ngOnInit() {
    this.onWindowScroll(event);
    document.getElementById('eventos').hidden=true;
    document.getElementById('reg-btn').hidden=true;
    window.scroll(0, 0);
  }

}
