import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})

export class InicioComponent implements OnInit {

  imageUrlArray: string[] = [
    "../../assets/img/home/home1.png",
    "../../assets/img/woman-phone.png",
  ];

  constructor() { }

  ngOnInit() {
     window.scroll(0, 0);
  }

}
