import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DescargasComponent } from './descargas/descargas.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { ContactoComponent } from './contacto/contacto.component';
import { InicioComponent } from './inicio/inicio.component';

import { PromocionesComponent } from './promociones/promociones.component';
import { InicioSilvaEvComponent } from './inicio-silva-ev/inicio-silva-ev.component';
import { ProductosSilvaEvComponent } from './productos-silva-ev/productos-silva-ev.component';

import { InicioLabSilvaComponent } from './inicio-lab-silva/inicio-lab-silva.component';
import { ServiciosLabSilvaComponent } from './servicios-lab-silva/servicios-lab-silva.component';
import { PromocionesLabSilvaComponent } from './promociones-lab-silva/promociones-lab-silva.component';

import { LandingComponent }from './landing/landing.component';

import { PagoTdEvComponent } from './pago-td-ev/pago-td-ev.component';
import { CitasTdEvComponent } from './citas-td-ev/citas-td-ev.component';


const ROUTES: Routes = [
  
  { path: 'lab-silva/inicio', component: InicioLabSilvaComponent },
  { path: 'lab-silva/servicios', component: ServiciosLabSilvaComponent },
  { path: 'lab-silva/promociones', component: PromocionesLabSilvaComponent },
  { path: 'lab-silva/contacto', component: ContactoComponent },

  { path: 'silva-evolucion/contacto', component: ContactoComponent },
  { path: 'silva-evolucion/inicio', component: InicioSilvaEvComponent},
  { path: 'silva-evolucion/productos', component: ProductosSilvaEvComponent },
  { path: 'silva-evolucion/promociones', component: PromocionesComponent },

  { path: '3d-evolution/inicio', component: InicioComponent},
  { path: '3d-evolution/servicios', component: ServiciosComponent },
  { path: '3d-evolution/pago', component: PagoTdEvComponent },
  { path: '3d-evolution/descargas', component: DescargasComponent},
  { path: '3d-evolution/contacto', component: ContactoComponent },
  { path: '3d-evolution/citas', component: CitasTdEvComponent },
  
  { path: 'landing', component: LandingComponent},

  { path: '3d-evolution',
    redirectTo: '3d-evolution/inicio',
    pathMatch: 'full'
  },
  { path: 'silva-evolucion',
    redirectTo: 'silva-evolucion/inicio',
    pathMatch: 'full'
  },
  { path: 'lab-silva',
    redirectTo: 'lab-silva/inicio',
    pathMatch: 'full'
  },
  { path: '',
    pathMatch: 'full',
    redirectTo: 'landing'
  },
  { path: '**',
    pathMatch: 'full',
    redirectTo: 'landing'
  },
];

@NgModule({
  imports: [RouterModule.forRoot( ROUTES, { useHash: true } )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
