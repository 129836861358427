
export const gregorian = {
    "main": {
      "es-MX": {
        "identity": {
          "version": {
            "_cldrVersion": "36"
          },
          "language": "es",
          "territory": "MX"
        },
        "dates": {
          "calendars": {
            "gregorian": {
              "months": {
                "format": {
                  "abbreviated": {
                    "1": "ene.",
                    "2": "feb.",
                    "3": "mar.",
                    "4": "abr.",
                    "5": "may.",
                    "6": "jun.",
                    "7": "jul.",
                    "8": "ago.",
                    "9": "sep.",
                    "10": "oct.",
                    "11": "nov.",
                    "12": "dic."
                  },
                  "narrow": {
                    "1": "E",
                    "2": "F",
                    "3": "M",
                    "4": "A",
                    "5": "M",
                    "6": "J",
                    "7": "J",
                    "8": "A",
                    "9": "S",
                    "10": "O",
                    "11": "N",
                    "12": "D"
                  },
                  "wide": {
                    "1": "enero",
                    "2": "febrero",
                    "3": "marzo",
                    "4": "abril",
                    "5": "mayo",
                    "6": "junio",
                    "7": "julio",
                    "8": "agosto",
                    "9": "septiembre",
                    "10": "octubre",
                    "11": "noviembre",
                    "12": "diciembre"
                  }
                },
                "stand-alone": {
                  "abbreviated": {
                    "1": "ene.",
                    "2": "feb.",
                    "3": "mar.",
                    "4": "abr.",
                    "5": "may.",
                    "6": "jun.",
                    "7": "jul.",
                    "8": "ago.",
                    "9": "sep.",
                    "10": "oct.",
                    "11": "nov.",
                    "12": "dic."
                  },
                  "narrow": {
                    "1": "E",
                    "2": "F",
                    "3": "M",
                    "4": "A",
                    "5": "M",
                    "6": "J",
                    "7": "J",
                    "8": "A",
                    "9": "S",
                    "10": "O",
                    "11": "N",
                    "12": "D"
                  },
                  "wide": {
                    "1": "enero",
                    "2": "febrero",
                    "3": "marzo",
                    "4": "abril",
                    "5": "mayo",
                    "6": "junio",
                    "7": "julio",
                    "8": "agosto",
                    "9": "septiembre",
                    "10": "octubre",
                    "11": "noviembre",
                    "12": "diciembre"
                  }
                }
              },
              "days": {
                "format": {
                  "abbreviated": {
                    "sun": "dom.",
                    "mon": "lun.",
                    "tue": "mar.",
                    "wed": "mié.",
                    "thu": "jue.",
                    "fri": "vie.",
                    "sat": "sáb."
                  },
                  "narrow": {
                    "sun": "D",
                    "mon": "L",
                    "tue": "M",
                    "wed": "M",
                    "thu": "J",
                    "fri": "V",
                    "sat": "S"
                  },
                  "short": {
                    "sun": "DO",
                    "mon": "LU",
                    "tue": "MA",
                    "wed": "MI",
                    "thu": "JU",
                    "fri": "VI",
                    "sat": "SA"
                  },
                  "wide": {
                    "sun": "domingo",
                    "mon": "lunes",
                    "tue": "martes",
                    "wed": "miércoles",
                    "thu": "jueves",
                    "fri": "viernes",
                    "sat": "sábado"
                  }
                },
                "stand-alone": {
                  "abbreviated": {
                    "sun": "dom.",
                    "mon": "lun.",
                    "tue": "mar.",
                    "wed": "mié.",
                    "thu": "jue.",
                    "fri": "vie.",
                    "sat": "sáb."
                  },
                  "narrow": {
                    "sun": "D",
                    "mon": "L",
                    "tue": "M",
                    "wed": "M",
                    "thu": "J",
                    "fri": "V",
                    "sat": "S"
                  },
                  "short": {
                    "sun": "DO",
                    "mon": "LU",
                    "tue": "MA",
                    "wed": "MI",
                    "thu": "JU",
                    "fri": "VI",
                    "sat": "SA"
                  },
                  "wide": {
                    "sun": "domingo",
                    "mon": "lunes",
                    "tue": "martes",
                    "wed": "miércoles",
                    "thu": "jueves",
                    "fri": "viernes",
                    "sat": "sábado"
                  }
                }
              },
              "quarters": {
                "format": {
                  "abbreviated": {
                    "1": "T1",
                    "2": "T2",
                    "3": "T3",
                    "4": "T4"
                  },
                  "narrow": {
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4"
                  },
                  "wide": {
                    "1": "1.er trimestre",
                    "2": "2.º trimestre",
                    "3": "3.er trimestre",
                    "4": "4.º trimestre"
                  }
                },
                "stand-alone": {
                  "abbreviated": {
                    "1": "T1",
                    "2": "T2",
                    "3": "T3",
                    "4": "T4"
                  },
                  "narrow": {
                    "1": "1T",
                    "2": "2T",
                    "3": "3T",
                    "4": "4T"
                  },
                  "wide": {
                    "1": "1.er trimestre",
                    "2": "2.º trimestre",
                    "3": "3.er trimestre",
                    "4": "4.º trimestre"
                  }
                }
              },
              "dayPeriods": {
                "format": {
                  "abbreviated": {
                    "am": "a. m.",
                    "noon": "del mediodía",
                    "pm": "p. m.",
                    "morning1": "de la madrugada",
                    "morning2": "de la mañana",
                    "evening1": "de la tarde",
                    "night1": "de la noche"
                  },
                  "narrow": {
                    "am": "a. m.",
                    "noon": "del mediodía",
                    "pm": "p. m.",
                    "morning1": "de la madrugada",
                    "morning2": "mañana",
                    "evening1": "de la tarde",
                    "night1": "de la noche"
                  },
                  "wide": {
                    "am": "a. m.",
                    "noon": "del mediodía",
                    "pm": "p. m.",
                    "morning1": "de la madrugada",
                    "morning2": "de la mañana",
                    "evening1": "de la tarde",
                    "night1": "de la noche"
                  }
                },
                "stand-alone": {
                  "abbreviated": {
                    "am": "a. m.",
                    "noon": "mediodía",
                    "pm": "p. m.",
                    "morning1": "madrugada",
                    "morning2": "mañana",
                    "evening1": "tarde",
                    "night1": "noche"
                  },
                  "narrow": {
                    "am": "a. m.",
                    "noon": "mediodía",
                    "pm": "p. m.",
                    "morning1": "madrugada",
                    "morning2": "mañana",
                    "evening1": "tarde",
                    "night1": "noche"
                  },
                  "wide": {
                    "am": "a. m.",
                    "noon": "mediodía",
                    "pm": "p. m.",
                    "morning1": "madrugada",
                    "morning2": "mañana",
                    "evening1": "tarde",
                    "night1": "noche"
                  }
                }
              },
              "eras": {
                "eraNames": {
                  "0": "antes de Cristo",
                  "0-alt-variant": "antes de la era común",
                  "1": "después de Cristo",
                  "1-alt-variant": "era común"
                },
                "eraAbbr": {
                  "0": "a. C.",
                  "0-alt-variant": "a. e. c.",
                  "1": "d. C.",
                  "1-alt-variant": "e. c."
                },
                "eraNarrow": {
                  "0": "a. C.",
                  "0-alt-variant": "a. e. c.",
                  "1": "d. C.",
                  "1-alt-variant": "e. c."
                }
              },
              "dateFormats": {
                "full": "EEEE, d 'de' MMMM 'de' y",
                "long": "d 'de' MMMM 'de' y",
                "medium": "d MMM y",
                "short": "dd/MM/yy"
              },
              "timeFormats": {
                "full": "H:mm:ss zzzz",
                "long": "H:mm:ss z",
                "medium": "H:mm:ss",
                "short": "H:mm"
              },
              "dateTimeFormats": {
                "full": "{1} 'a' 'las' {0}",
                "long": "{1} 'a' 'las' {0}",
                "medium": "{1} {0}",
                "short": "{1} {0}",
                "availableFormats": {
                  "Bh": "h B",
                  "Bhm": "h:mm B",
                  "Bhms": "h:mm:ss B",
                  "d": "d",
                  "E": "ccc",
                  "EBhm": "E h:mm B",
                  "EBhms": "E h:mm:ss B",
                  "Ed": "E d",
                  "Ehm": "E h:mm a",
                  "EHm": "E HH:mm",
                  "Ehms": "E h:mm:ss a",
                  "EHms": "E HH:mm:ss",
                  "Gy": "y G",
                  "GyMMM": "MMM y G",
                  "GyMMMd": "d MMM y G",
                  "GyMMMEd": "E, d MMM y G",
                  "GyMMMM": "MMMM 'de' y G",
                  "GyMMMMd": "d 'de' MMMM 'de' y G",
                  "GyMMMMEd": "E, d 'de' MMMM 'de' y G",
                  "h": "h a",
                  "H": "HH",
                  "hm": "h:mm a",
                  "Hm": "H:mm",
                  "hms": "h:mm:ss a",
                  "Hms": "H:mm:ss",
                  "hmsv": "h:mm:ss a v",
                  "Hmsv": "HH:mm:ss v",
                  "hmsvvvv": "h:mm:ss a (vvvv)",
                  "Hmsvvvv": "HH:mm:ss (vvvv)",
                  "hmv": "h:mm a v",
                  "Hmv": "HH:mm v",
                  "M": "L",
                  "Md": "d/M",
                  "MEd": "E, d/M",
                  "MMd": "d/MM",
                  "MMdd": "dd/MM",
                  "MMM": "LLL",
                  "MMMd": "d MMM",
                  "MMMdd": "dd-MMM",
                  "MMMEd": "E d 'de' MMM",
                  "MMMMd": "d 'de' MMMM",
                  "MMMMEd": "E, d 'de' MMMM",
                  "MMMMW-count-one": "'semana' W 'de' MMMM",
                  "MMMMW-count-other": "'semana' W 'de' MMMM",
                  "ms": "mm:ss",
                  "y": "y",
                  "yM": "M/y",
                  "yMd": "d/M/y",
                  "yMEd": "E, d/M/y",
                  "yMM": "MM/y",
                  "yMMM": "MMMM 'de' y",
                  "yMMMd": "d 'de' MMMM 'de' y",
                  "yMMMEd": "EEE, d 'de' MMMM 'de' y",
                  "yMMMM": "MMMM 'de' y",
                  "yMMMMd": "d 'de' MMMM 'de' y",
                  "yMMMMEd": "EEE, d 'de' MMMM 'de' y",
                  "yQQQ": "QQQ y",
                  "yQQQQ": "QQQQ 'de' y",
                  "yw-count-one": "'semana' w 'de' Y",
                  "yw-count-other": "'semana' w 'de' Y"
                },
                "appendItems": {
                  "Day": "{0} ({2}: {1})",
                  "Day-Of-Week": "{0} {1}",
                  "Era": "{1} {0}",
                  "Hour": "{0} ({2}: {1})",
                  "Minute": "{0} ({2}: {1})",
                  "Month": "{0} ({2}: {1})",
                  "Quarter": "{0} ({2}: {1})",
                  "Second": "{0} ({2}: {1})",
                  "Timezone": "{0} {1}",
                  "Week": "{0} ({2}: {1})",
                  "Year": "{1} {0}"
                },
                "intervalFormats": {
                  "intervalFormatFallback": "{0} – {1}",
                  "Bh": {
                    "B": "h B – h B",
                    "h": "h–h B"
                  },
                  "Bhm": {
                    "B": "h:mm B – h:mm B",
                    "h": "h:mm–h:mm B",
                    "m": "h:mm–h:mm B"
                  },
                  "d": {
                    "d": "d–d"
                  },
                  "Gy": {
                    "G": "y G – y G",
                    "y": "y–y G"
                  },
                  "GyM": {
                    "G": "MM/y GGGGG – MM/y GGGGG",
                    "M": "MM/y – MM/y GGGGG",
                    "y": "MM/y – MM/y GGGGG"
                  },
                  "GyMd": {
                    "d": "dd/MM/y – dd/MM/y GGGGG",
                    "G": "dd/MM/y GGGGG – dd/MM/y GGGGG",
                    "M": "dd/MM/y – dd/MM/y GGGGG",
                    "y": "dd/MM/y – dd/MM/y GGGGG"
                  },
                  "GyMEd": {
                    "d": "E, dd/MM/y – E, dd/MM/y GGGGG",
                    "G": "E, dd/MM/y GGGGG – E, dd/MM/y GGGGG",
                    "M": "E, dd/MM/y – E, dd/MM/y GGGGG",
                    "y": "E, dd/MM/y – E, dd/MM/y GGGGG"
                  },
                  "GyMMM": {
                    "G": "MMM 'de' y G – MMM 'de' y G",
                    "M": "MMM–MMM 'de' y G",
                    "y": "MMM 'de' y – MMM 'de' y G"
                  },
                  "GyMMMd": {
                    "d": "d–d 'de' MMM 'de' y G",
                    "G": "d 'de' MMM 'de' y G – d 'de' MMM 'de' y G",
                    "M": "d 'de' MMM – d 'de' MMM 'de' y G",
                    "y": "d 'de' MMM 'de' y – d 'de' MMM 'de' y G"
                  },
                  "GyMMMEd": {
                    "d": "E d 'de' MMM – E d 'de' MMM 'de' y G",
                    "G": "E d 'de' MMM 'de' y G – E d 'de' MMM 'de' y G",
                    "M": "E d 'de' MMM – E d 'de' MMM 'de' y G",
                    "y": "E d 'de' MMM 'de' y – E d 'de' MMM 'de' y G"
                  },
                  "h": {
                    "a": "h a – h a",
                    "h": "h–h a"
                  },
                  "H": {
                    "H": "HH–HH"
                  },
                  "hm": {
                    "a": "h:mm a – h:mm a",
                    "h": "h:mm–h:mm a",
                    "m": "h:mm–h:mm a"
                  },
                  "Hm": {
                    "H": "HH:mm–HH:mm",
                    "m": "HH:mm–HH:mm"
                  },
                  "hmv": {
                    "a": "h:mm a – h:mm a v",
                    "h": "h:mm–h:mm a v",
                    "m": "h:mm–h:mm a v"
                  },
                  "Hmv": {
                    "H": "HH:mm–HH:mm v",
                    "m": "HH:mm–HH:mm v"
                  },
                  "hv": {
                    "a": "h a – h a v",
                    "h": "h–h a v"
                  },
                  "Hv": {
                    "H": "HH–HH v"
                  },
                  "M": {
                    "M": "M–M"
                  },
                  "Md": {
                    "d": "d/M – d/M",
                    "M": "d/M – d/M"
                  },
                  "MEd": {
                    "d": "E, d/M – E, d/M",
                    "M": "E, M/d–E, M/d"
                  },
                  "MMM": {
                    "M": "MMM–MMM"
                  },
                  "MMMd": {
                    "d": "d–d 'de' MMM",
                    "M": "d 'de' MMM – d 'de' MMM"
                  },
                  "MMMEd": {
                    "d": "E d 'de' MMM – E d 'de' MMM",
                    "M": "E d 'de' MMM – E d 'de' MMM"
                  },
                  "MMMMd": {
                    "d": "d–d 'de' MMMM",
                    "M": "d 'de' MMMM–d 'de' MMMM"
                  },
                  "MMMMEd": {
                    "d": "E, d 'de' MMMM–E, d 'de' MMMM",
                    "M": "E, d 'de' MMMM–E, d 'de' MMMM"
                  },
                  "y": {
                    "y": "y–y"
                  },
                  "yM": {
                    "M": "M/y – M/y",
                    "y": "M/y – M/y"
                  },
                  "yMd": {
                    "d": "d/M/y – d/M/y",
                    "M": "d/M/y – d/M/y",
                    "y": "d/M/y – d/M/y"
                  },
                  "yMEd": {
                    "d": "E, d/M/y – E, d/M/y",
                    "M": "E, d/M/y – E, d/M/y",
                    "y": "E, d/M/y – E, d/M/y"
                  },
                  "yMMM": {
                    "M": "MMM–MMM 'de' y",
                    "y": "MMM 'de' y – MMM 'de' y"
                  },
                  "yMMMd": {
                    "d": "d–d 'de' MMM 'de' y",
                    "M": "d 'de' MMM – d 'de' MMM y",
                    "y": "d 'de' MMM 'de' y – d 'de' MMM 'de' y"
                  },
                  "yMMMEd": {
                    "d": "E d 'de' MMM – E d 'de' MMM 'de' y",
                    "M": "E d 'de' MMM – E d 'de' MMM 'de' y",
                    "y": "E d 'de' MMM 'de' y – E d 'de' MMM 'de' y"
                  },
                  "yMMMM": {
                    "M": "MMMM–MMMM 'de' y",
                    "y": "MMMM 'de' y – MMMM 'de' y"
                  },
                  "yMMMMd": {
                    "d": "d–d 'de' MMMM 'de' y",
                    "M": "d 'de' MMMM–d 'de' MMMM 'de' y",
                    "y": "d 'de' MMMM 'de' y–d 'de' MMMM 'de' y"
                  },
                  "yMMMMEd": {
                    "d": "E, d 'de' MMMM–E, d 'de' MMMM 'de' y",
                    "M": "E, d 'de' MMMM–E, d 'de' MMMM 'de' y",
                    "y": "E, d 'de' MMMM 'de' y–E, d 'de' MMMM 'de' y"
                  }
                }
              }
            }
          }
        }
      }
    }
};
  
export const numbers = {
    "main": {
      "es-MX": {
        "identity": {
          "version": {
            "_cldrVersion": "36"
          },
          "language": "es",
          "territory": "MX"
        },
        "numbers": {
          "defaultNumberingSystem": "latn",
          "otherNumberingSystems": {
            "native": "latn"
          },
          "minimumGroupingDigits": "2",
          "symbols-numberSystem-latn": {
            "decimal": ".",
            "group": ",",
            "list": ";",
            "percentSign": "%",
            "plusSign": "+",
            "minusSign": "-",
            "exponential": "E",
            "superscriptingExponent": "×",
            "perMille": "‰",
            "infinity": "∞",
            "nan": "NaN",
            "timeSeparator": ":"
          },
          "decimalFormats-numberSystem-latn": {
            "standard": "#,##0.###",
            "long": {
              "decimalFormat": {
                "1000-count-one": "0 mil",
                "1000-count-other": "0 mil",
                "10000-count-one": "00 mil",
                "10000-count-other": "00 mil",
                "100000-count-one": "000 mil",
                "100000-count-other": "000 mil",
                "1000000-count-one": "0 millón",
                "1000000-count-other": "0 millones",
                "10000000-count-one": "00 millones",
                "10000000-count-other": "00 millones",
                "100000000-count-one": "000 millones",
                "100000000-count-other": "000 millones",
                "1000000000-count-one": "0 mil millones",
                "1000000000-count-other": "0 mil millones",
                "10000000000-count-one": "00 mil millones",
                "10000000000-count-other": "00 mil millones",
                "100000000000-count-one": "000 mil millones",
                "100000000000-count-other": "000 mil millones",
                "1000000000000-count-one": "0 billón",
                "1000000000000-count-other": "0 billones",
                "10000000000000-count-one": "00 billones",
                "10000000000000-count-other": "00 billones",
                "100000000000000-count-one": "000 billones",
                "100000000000000-count-other": "000 billones"
              }
            },
            "short": {
              "decimalFormat": {
                "1000-count-one": "0 k",
                "1000-count-other": "0 k",
                "10000-count-one": "00 k",
                "10000-count-other": "00 k",
                "100000-count-one": "000 k",
                "100000-count-other": "000 k",
                "1000000-count-one": "0 M",
                "1000000-count-other": "0 M",
                "10000000-count-one": "00 M",
                "10000000-count-other": "00 M",
                "100000000-count-one": "000 M",
                "100000000-count-other": "000 M",
                "1000000000-count-one": "0000 M",
                "1000000000-count-other": "0000 M",
                "10000000000-count-one": "00 mil M",
                "10000000000-count-other": "00 mil M",
                "100000000000-count-one": "000 mil M",
                "100000000000-count-other": "000 mil M",
                "1000000000000-count-one": "0 B",
                "1000000000000-count-other": "0 B",
                "10000000000000-count-one": "00 B",
                "10000000000000-count-other": "00 B",
                "100000000000000-count-one": "000 B",
                "100000000000000-count-other": "000 B"
              }
            }
          },
          "scientificFormats-numberSystem-latn": {
            "standard": "#E0"
          },
          "percentFormats-numberSystem-latn": {
            "standard": "#,##0 %"
          },
          "currencyFormats-numberSystem-latn": {
            "currencySpacing": {
              "beforeCurrency": {
                "currencyMatch": "[:^S:]",
                "surroundingMatch": "[:digit:]",
                "insertBetween": " "
              },
              "afterCurrency": {
                "currencyMatch": "[:^S:]",
                "surroundingMatch": "[:digit:]",
                "insertBetween": " "
              }
            },
            "standard": "¤#,##0.00",
            "accounting": "¤#,##0.00",
            "short": {
              "standard": {
                "1000-count-one": "0 k¤",
                "1000-count-other": "0 k¤",
                "10000-count-one": "00 k¤",
                "10000-count-other": "00 k¤",
                "100000-count-one": "000 k¤",
                "100000-count-other": "000 k¤",
                "1000000-count-one": "0 M¤",
                "1000000-count-other": "0 M¤",
                "10000000-count-one": "00 M¤",
                "10000000-count-other": "00 M¤",
                "100000000-count-one": "000 M¤",
                "100000000-count-other": "000 M¤",
                "1000000000-count-one": "0000 M¤",
                "1000000000-count-other": "0000 M¤",
                "10000000000-count-one": "00 MRD ¤",
                "10000000000-count-other": "00 MRD ¤",
                "100000000000-count-one": "000 MRD ¤",
                "100000000000-count-other": "000 MRD ¤",
                "1000000000000-count-one": "0 B¤",
                "1000000000000-count-other": "0 B¤",
                "10000000000000-count-one": "00 B¤",
                "10000000000000-count-other": "00 B¤",
                "100000000000000-count-one": "000 B¤",
                "100000000000000-count-other": "000 B¤"
              }
            },
            "unitPattern-count-one": "{0} {1}",
            "unitPattern-count-other": "{0} {1}"
          },
          "miscPatterns-numberSystem-latn": {
            "approximately": "~{0}",
            "atLeast": "Más de {0}",
            "atMost": "≤{0}",
            "range": "{0}-{1}"
          },
          "minimalPairs": {
            "pluralMinimalPairs-count-one": "{0} día",
            "pluralMinimalPairs-count-other": "{0} días",
            "other": "Toma la {0}.ª a la derecha."
          }
        }
      }
    }
};
  
export const timeZoneNames = {
    "main": {
      "es-MX": {
        "identity": {
          "version": {
            "_cldrVersion": "36"
          },
          "language": "es",
          "territory": "MX"
        },
        "dates": {
          "timeZoneNames": {
            "hourFormat": "+HH:mm;-HH:mm",
            "gmtFormat": "GMT{0}",
            "gmtZeroFormat": "GMT",
            "regionFormat": "hora de {0}",
            "regionFormat-type-daylight": "hora de verano de {0}",
            "regionFormat-type-standard": "hora estándar de {0}",
            "fallbackFormat": "{1} ({0})",
            "zone": {
              "America": {
                "Adak": {
                  "exemplarCity": "Adak"
                },
                "Anchorage": {
                  "exemplarCity": "Anchorage"
                },
                "Anguilla": {
                  "exemplarCity": "Anguila"
                },
                "Antigua": {
                  "exemplarCity": "Antigua"
                },
                "Araguaina": {
                  "exemplarCity": "Araguaína"
                },
                "Argentina": {
                  "Rio_Gallegos": {
                    "exemplarCity": "Río Gallegos"
                  },
                  "San_Juan": {
                    "exemplarCity": "San Juan"
                  },
                  "Ushuaia": {
                    "exemplarCity": "Ushuaia"
                  },
                  "La_Rioja": {
                    "exemplarCity": "La Rioja"
                  },
                  "San_Luis": {
                    "exemplarCity": "San Luis"
                  },
                  "Salta": {
                    "exemplarCity": "Salta"
                  },
                  "Tucuman": {
                    "exemplarCity": "Tucumán"
                  }
                },
                "Aruba": {
                  "exemplarCity": "Aruba"
                },
                "Asuncion": {
                  "exemplarCity": "Asunción"
                },
                "Bahia": {
                  "exemplarCity": "Bahía"
                },
                "Bahia_Banderas": {
                  "exemplarCity": "Bahía de Banderas"
                },
                "Barbados": {
                  "exemplarCity": "Barbados"
                },
                "Belem": {
                  "exemplarCity": "Belén"
                },
                "Belize": {
                  "exemplarCity": "Belice"
                },
                "Blanc-Sablon": {
                  "exemplarCity": "Blanc-Sablon"
                },
                "Boa_Vista": {
                  "exemplarCity": "Boa Vista"
                },
                "Bogota": {
                  "exemplarCity": "Bogotá"
                },
                "Boise": {
                  "exemplarCity": "Boise"
                },
                "Buenos_Aires": {
                  "exemplarCity": "Buenos Aires"
                },
                "Cambridge_Bay": {
                  "exemplarCity": "Cambridge Bay"
                },
                "Campo_Grande": {
                  "exemplarCity": "Campo Grande"
                },
                "Cancun": {
                  "exemplarCity": "Cancún"
                },
                "Caracas": {
                  "exemplarCity": "Caracas"
                },
                "Catamarca": {
                  "exemplarCity": "Catamarca"
                },
                "Cayenne": {
                  "exemplarCity": "Cayena"
                },
                "Cayman": {
                  "exemplarCity": "Caimán"
                },
                "Chicago": {
                  "exemplarCity": "Chicago"
                },
                "Chihuahua": {
                  "exemplarCity": "Chihuahua"
                },
                "Coral_Harbour": {
                  "exemplarCity": "Atikokan"
                },
                "Cordoba": {
                  "exemplarCity": "Córdoba"
                },
                "Costa_Rica": {
                  "exemplarCity": "Costa Rica"
                },
                "Creston": {
                  "exemplarCity": "Creston"
                },
                "Cuiaba": {
                  "exemplarCity": "Cuiabá"
                },
                "Curacao": {
                  "exemplarCity": "Curazao"
                },
                "Danmarkshavn": {
                  "exemplarCity": "Danmarkshavn"
                },
                "Dawson": {
                  "exemplarCity": "Dawson"
                },
                "Dawson_Creek": {
                  "exemplarCity": "Dawson Creek"
                },
                "Denver": {
                  "exemplarCity": "Denver"
                },
                "Detroit": {
                  "exemplarCity": "Detroit"
                },
                "Dominica": {
                  "exemplarCity": "Dominica"
                },
                "Edmonton": {
                  "exemplarCity": "Edmonton"
                },
                "Eirunepe": {
                  "exemplarCity": "Eirunepé"
                },
                "El_Salvador": {
                  "exemplarCity": "El Salvador"
                },
                "Fort_Nelson": {
                  "exemplarCity": "Fort Nelson"
                },
                "Fortaleza": {
                  "exemplarCity": "Fortaleza"
                },
                "Glace_Bay": {
                  "exemplarCity": "Glace Bay"
                },
                "Godthab": {
                  "exemplarCity": "Nuuk"
                },
                "Goose_Bay": {
                  "exemplarCity": "Goose Bay"
                },
                "Grand_Turk": {
                  "exemplarCity": "Gran Turca"
                },
                "Grenada": {
                  "exemplarCity": "Granada"
                },
                "Guadeloupe": {
                  "exemplarCity": "Guadalupe"
                },
                "Guatemala": {
                  "exemplarCity": "Guatemala"
                },
                "Guayaquil": {
                  "exemplarCity": "Guayaquil"
                },
                "Guyana": {
                  "exemplarCity": "Guyana"
                },
                "Halifax": {
                  "exemplarCity": "Halifax"
                },
                "Havana": {
                  "exemplarCity": "La Habana"
                },
                "Hermosillo": {
                  "exemplarCity": "Hermosillo"
                },
                "Indiana": {
                  "Vincennes": {
                    "exemplarCity": "Vincennes, Indiana"
                  },
                  "Petersburg": {
                    "exemplarCity": "Petersburg, Indiana"
                  },
                  "Tell_City": {
                    "exemplarCity": "Tell City, Indiana"
                  },
                  "Knox": {
                    "exemplarCity": "Knox, Indiana"
                  },
                  "Winamac": {
                    "exemplarCity": "Winamac, Indiana"
                  },
                  "Marengo": {
                    "exemplarCity": "Marengo, Indiana"
                  },
                  "Vevay": {
                    "exemplarCity": "Vevay, Indiana"
                  }
                },
                "Indianapolis": {
                  "exemplarCity": "Indianápolis"
                },
                "Inuvik": {
                  "exemplarCity": "Inuvik"
                },
                "Iqaluit": {
                  "exemplarCity": "Iqaluit"
                },
                "Jamaica": {
                  "exemplarCity": "Jamaica"
                },
                "Jujuy": {
                  "exemplarCity": "Jujuy"
                },
                "Juneau": {
                  "exemplarCity": "Juneau"
                },
                "Kentucky": {
                  "Monticello": {
                    "exemplarCity": "Monticello, Kentucky"
                  }
                },
                "Kralendijk": {
                  "exemplarCity": "Kralendijk"
                },
                "La_Paz": {
                  "exemplarCity": "La Paz"
                },
                "Lima": {
                  "exemplarCity": "Lima"
                },
                "Los_Angeles": {
                  "exemplarCity": "Los Ángeles"
                },
                "Louisville": {
                  "exemplarCity": "Louisville"
                },
                "Lower_Princes": {
                  "exemplarCity": "Lower Prince’s Quarter"
                },
                "Maceio": {
                  "exemplarCity": "Maceió"
                },
                "Managua": {
                  "exemplarCity": "Managua"
                },
                "Manaus": {
                  "exemplarCity": "Manaos"
                },
                "Marigot": {
                  "exemplarCity": "Marigot"
                },
                "Martinique": {
                  "exemplarCity": "Martinica"
                },
                "Matamoros": {
                  "exemplarCity": "Matamoros"
                },
                "Mazatlan": {
                  "exemplarCity": "Mazatlán"
                },
                "Mendoza": {
                  "exemplarCity": "Mendoza"
                },
                "Menominee": {
                  "exemplarCity": "Menominee"
                },
                "Merida": {
                  "exemplarCity": "Mérida"
                },
                "Metlakatla": {
                  "exemplarCity": "Metlakatla"
                },
                "Mexico_City": {
                  "exemplarCity": "Ciudad de México"
                },
                "Miquelon": {
                  "exemplarCity": "Miquelón"
                },
                "Moncton": {
                  "exemplarCity": "Moncton"
                },
                "Monterrey": {
                  "exemplarCity": "Monterrey"
                },
                "Montevideo": {
                  "exemplarCity": "Montevideo"
                },
                "Montserrat": {
                  "exemplarCity": "Montserrat"
                },
                "Nassau": {
                  "exemplarCity": "Nasáu"
                },
                "New_York": {
                  "exemplarCity": "Nueva York"
                },
                "Nipigon": {
                  "exemplarCity": "Nipigon"
                },
                "Nome": {
                  "exemplarCity": "Nome"
                },
                "Noronha": {
                  "exemplarCity": "Noronha"
                },
                "North_Dakota": {
                  "Beulah": {
                    "exemplarCity": "Beulah, Dakota del Norte"
                  },
                  "New_Salem": {
                    "exemplarCity": "New Salem, Dakota del Norte"
                  },
                  "Center": {
                    "exemplarCity": "Center, Dakota del Norte"
                  }
                },
                "Ojinaga": {
                  "exemplarCity": "Ojinaga"
                },
                "Panama": {
                  "exemplarCity": "Panamá"
                },
                "Pangnirtung": {
                  "exemplarCity": "Pangnirtung"
                },
                "Paramaribo": {
                  "exemplarCity": "Paramaribo"
                },
                "Phoenix": {
                  "exemplarCity": "Phoenix"
                },
                "Port-au-Prince": {
                  "exemplarCity": "Puerto Príncipe"
                },
                "Port_of_Spain": {
                  "exemplarCity": "Puerto España"
                },
                "Porto_Velho": {
                  "exemplarCity": "Porto Velho"
                },
                "Puerto_Rico": {
                  "exemplarCity": "Puerto Rico"
                },
                "Punta_Arenas": {
                  "exemplarCity": "Punta Arenas"
                },
                "Rainy_River": {
                  "exemplarCity": "Rainy River"
                },
                "Rankin_Inlet": {
                  "exemplarCity": "Rankin Inlet"
                },
                "Recife": {
                  "exemplarCity": "Recife"
                },
                "Regina": {
                  "exemplarCity": "Regina"
                },
                "Resolute": {
                  "exemplarCity": "Resolute"
                },
                "Rio_Branco": {
                  "exemplarCity": "Rio Branco"
                },
                "Santa_Isabel": {
                  "exemplarCity": "Santa Isabel"
                },
                "Santarem": {
                  "exemplarCity": "Santarém"
                },
                "Santiago": {
                  "exemplarCity": "Santiago"
                },
                "Santo_Domingo": {
                  "exemplarCity": "Santo Domingo"
                },
                "Sao_Paulo": {
                  "exemplarCity": "São Paulo"
                },
                "Scoresbysund": {
                  "exemplarCity": "Ittoqqortoormiit"
                },
                "Sitka": {
                  "exemplarCity": "Sitka"
                },
                "St_Barthelemy": {
                  "exemplarCity": "San Bartolomé"
                },
                "St_Johns": {
                  "exemplarCity": "San Juan de Terranova"
                },
                "St_Kitts": {
                  "exemplarCity": "San Cristóbal"
                },
                "St_Lucia": {
                  "exemplarCity": "Santa Lucía"
                },
                "St_Thomas": {
                  "exemplarCity": "St. Thomas"
                },
                "St_Vincent": {
                  "exemplarCity": "San Vicente"
                },
                "Swift_Current": {
                  "exemplarCity": "Swift Current"
                },
                "Tegucigalpa": {
                  "exemplarCity": "Tegucigalpa"
                },
                "Thule": {
                  "exemplarCity": "Thule"
                },
                "Thunder_Bay": {
                  "exemplarCity": "Thunder Bay"
                },
                "Tijuana": {
                  "exemplarCity": "Tijuana"
                },
                "Toronto": {
                  "exemplarCity": "Toronto"
                },
                "Tortola": {
                  "exemplarCity": "Tórtola"
                },
                "Vancouver": {
                  "exemplarCity": "Vancouver"
                },
                "Whitehorse": {
                  "exemplarCity": "Whitehorse"
                },
                "Winnipeg": {
                  "exemplarCity": "Winnipeg"
                },
                "Yakutat": {
                  "exemplarCity": "Yakutat"
                },
                "Yellowknife": {
                  "exemplarCity": "Yellowknife"
                }
              },
              "Atlantic": {
                "Azores": {
                  "exemplarCity": "Azores"
                },
                "Bermuda": {
                  "exemplarCity": "Bermudas"
                },
                "Canary": {
                  "exemplarCity": "Islas Canarias"
                },
                "Cape_Verde": {
                  "exemplarCity": "Cabo Verde"
                },
                "Faeroe": {
                  "exemplarCity": "Islas Feroe"
                },
                "Madeira": {
                  "exemplarCity": "Madeira"
                },
                "Reykjavik": {
                  "exemplarCity": "Reikiavik"
                },
                "South_Georgia": {
                  "exemplarCity": "Georgia del Sur"
                },
                "St_Helena": {
                  "exemplarCity": "Santa Elena"
                },
                "Stanley": {
                  "exemplarCity": "Stanley"
                }
              },
              "Europe": {
                "Amsterdam": {
                  "exemplarCity": "Ámsterdam"
                },
                "Andorra": {
                  "exemplarCity": "Andorra"
                },
                "Astrakhan": {
                  "exemplarCity": "Astracán"
                },
                "Athens": {
                  "exemplarCity": "Atenas"
                },
                "Belgrade": {
                  "exemplarCity": "Belgrado"
                },
                "Berlin": {
                  "exemplarCity": "Berlín"
                },
                "Bratislava": {
                  "exemplarCity": "Bratislava"
                },
                "Brussels": {
                  "exemplarCity": "Bruselas"
                },
                "Bucharest": {
                  "exemplarCity": "Bucarest"
                },
                "Budapest": {
                  "exemplarCity": "Budapest"
                },
                "Busingen": {
                  "exemplarCity": "Büsingen"
                },
                "Chisinau": {
                  "exemplarCity": "Chisináu"
                },
                "Copenhagen": {
                  "exemplarCity": "Copenhague"
                },
                "Dublin": {
                  "long": {
                    "daylight": "hora estándar de Irlanda"
                  },
                  "exemplarCity": "Dublín"
                },
                "Gibraltar": {
                  "exemplarCity": "Gibraltar"
                },
                "Guernsey": {
                  "exemplarCity": "Guernsey"
                },
                "Helsinki": {
                  "exemplarCity": "Helsinki"
                },
                "Isle_of_Man": {
                  "exemplarCity": "Isla de Man"
                },
                "Istanbul": {
                  "exemplarCity": "Estambul"
                },
                "Jersey": {
                  "exemplarCity": "Jersey"
                },
                "Kaliningrad": {
                  "exemplarCity": "Kaliningrado"
                },
                "Kiev": {
                  "exemplarCity": "Kiev"
                },
                "Kirov": {
                  "exemplarCity": "Kírov"
                },
                "Lisbon": {
                  "exemplarCity": "Lisboa"
                },
                "Ljubljana": {
                  "exemplarCity": "Liubliana"
                },
                "London": {
                  "long": {
                    "daylight": "hora de verano británica"
                  },
                  "exemplarCity": "Londres"
                },
                "Luxembourg": {
                  "exemplarCity": "Luxemburgo"
                },
                "Madrid": {
                  "exemplarCity": "Madrid"
                },
                "Malta": {
                  "exemplarCity": "Malta"
                },
                "Mariehamn": {
                  "exemplarCity": "Mariehamn"
                },
                "Minsk": {
                  "exemplarCity": "Minsk"
                },
                "Monaco": {
                  "exemplarCity": "Mónaco"
                },
                "Moscow": {
                  "exemplarCity": "Moscú"
                },
                "Oslo": {
                  "exemplarCity": "Oslo"
                },
                "Paris": {
                  "exemplarCity": "París"
                },
                "Podgorica": {
                  "exemplarCity": "Podgorica"
                },
                "Prague": {
                  "exemplarCity": "Praga"
                },
                "Riga": {
                  "exemplarCity": "Riga"
                },
                "Rome": {
                  "exemplarCity": "Roma"
                },
                "Samara": {
                  "exemplarCity": "Samara"
                },
                "San_Marino": {
                  "exemplarCity": "San Marino"
                },
                "Sarajevo": {
                  "exemplarCity": "Sarajevo"
                },
                "Saratov": {
                  "exemplarCity": "Sarátov"
                },
                "Simferopol": {
                  "exemplarCity": "Simferópol"
                },
                "Skopje": {
                  "exemplarCity": "Skopie"
                },
                "Sofia": {
                  "exemplarCity": "Sofía"
                },
                "Stockholm": {
                  "exemplarCity": "Estocolmo"
                },
                "Tallinn": {
                  "exemplarCity": "Tallin"
                },
                "Tirane": {
                  "exemplarCity": "Tirana"
                },
                "Ulyanovsk": {
                  "exemplarCity": "Uliánovsk"
                },
                "Uzhgorod": {
                  "exemplarCity": "Úzhgorod"
                },
                "Vaduz": {
                  "exemplarCity": "Vaduz"
                },
                "Vatican": {
                  "exemplarCity": "El Vaticano"
                },
                "Vienna": {
                  "exemplarCity": "Viena"
                },
                "Vilnius": {
                  "exemplarCity": "Vilna"
                },
                "Volgograd": {
                  "exemplarCity": "Volgogrado"
                },
                "Warsaw": {
                  "exemplarCity": "Varsovia"
                },
                "Zagreb": {
                  "exemplarCity": "Zagreb"
                },
                "Zaporozhye": {
                  "exemplarCity": "Zaporiyia"
                },
                "Zurich": {
                  "exemplarCity": "Zúrich"
                }
              },
              "Africa": {
                "Abidjan": {
                  "exemplarCity": "Abiyán"
                },
                "Accra": {
                  "exemplarCity": "Acra"
                },
                "Addis_Ababa": {
                  "exemplarCity": "Addis Abeba"
                },
                "Algiers": {
                  "exemplarCity": "Argel"
                },
                "Asmera": {
                  "exemplarCity": "Asmara"
                },
                "Bamako": {
                  "exemplarCity": "Bamako"
                },
                "Bangui": {
                  "exemplarCity": "Bangui"
                },
                "Banjul": {
                  "exemplarCity": "Banjul"
                },
                "Bissau": {
                  "exemplarCity": "Bisáu"
                },
                "Blantyre": {
                  "exemplarCity": "Blantyre"
                },
                "Brazzaville": {
                  "exemplarCity": "Brazzaville"
                },
                "Bujumbura": {
                  "exemplarCity": "Buyumbura"
                },
                "Cairo": {
                  "exemplarCity": "El Cairo"
                },
                "Casablanca": {
                  "exemplarCity": "Casablanca"
                },
                "Ceuta": {
                  "exemplarCity": "Ceuta"
                },
                "Conakry": {
                  "exemplarCity": "Conakri"
                },
                "Dakar": {
                  "exemplarCity": "Dakar"
                },
                "Dar_es_Salaam": {
                  "exemplarCity": "Dar es-Salaam"
                },
                "Djibouti": {
                  "exemplarCity": "Yibuti"
                },
                "Douala": {
                  "exemplarCity": "Duala"
                },
                "El_Aaiun": {
                  "exemplarCity": "El Aaiún"
                },
                "Freetown": {
                  "exemplarCity": "Freetown"
                },
                "Gaborone": {
                  "exemplarCity": "Gaborone"
                },
                "Harare": {
                  "exemplarCity": "Harare"
                },
                "Johannesburg": {
                  "exemplarCity": "Johannesburgo"
                },
                "Juba": {
                  "exemplarCity": "Juba"
                },
                "Kampala": {
                  "exemplarCity": "Kampala"
                },
                "Khartoum": {
                  "exemplarCity": "Jartum"
                },
                "Kigali": {
                  "exemplarCity": "Kigali"
                },
                "Kinshasa": {
                  "exemplarCity": "Kinshasa"
                },
                "Lagos": {
                  "exemplarCity": "Lagos"
                },
                "Libreville": {
                  "exemplarCity": "Libreville"
                },
                "Lome": {
                  "exemplarCity": "Lomé"
                },
                "Luanda": {
                  "exemplarCity": "Luanda"
                },
                "Lubumbashi": {
                  "exemplarCity": "Lubumbashi"
                },
                "Lusaka": {
                  "exemplarCity": "Lusaka"
                },
                "Malabo": {
                  "exemplarCity": "Malabo"
                },
                "Maputo": {
                  "exemplarCity": "Maputo"
                },
                "Maseru": {
                  "exemplarCity": "Maseru"
                },
                "Mbabane": {
                  "exemplarCity": "Mbabane"
                },
                "Mogadishu": {
                  "exemplarCity": "Mogadiscio"
                },
                "Monrovia": {
                  "exemplarCity": "Monrovia"
                },
                "Nairobi": {
                  "exemplarCity": "Nairobi"
                },
                "Ndjamena": {
                  "exemplarCity": "Yamena"
                },
                "Niamey": {
                  "exemplarCity": "Niamey"
                },
                "Nouakchott": {
                  "exemplarCity": "Nuakchot"
                },
                "Ouagadougou": {
                  "exemplarCity": "Uagadugú"
                },
                "Porto-Novo": {
                  "exemplarCity": "Portonovo"
                },
                "Sao_Tome": {
                  "exemplarCity": "Santo Tomé"
                },
                "Tripoli": {
                  "exemplarCity": "Trípoli"
                },
                "Tunis": {
                  "exemplarCity": "Túnez"
                },
                "Windhoek": {
                  "exemplarCity": "Windhoek"
                }
              },
              "Asia": {
                "Aden": {
                  "exemplarCity": "Adén"
                },
                "Almaty": {
                  "exemplarCity": "Almatý"
                },
                "Amman": {
                  "exemplarCity": "Ammán"
                },
                "Anadyr": {
                  "exemplarCity": "Anádyr"
                },
                "Aqtau": {
                  "exemplarCity": "Aktau"
                },
                "Aqtobe": {
                  "exemplarCity": "Aktobé"
                },
                "Ashgabat": {
                  "exemplarCity": "Asjabad"
                },
                "Atyrau": {
                  "exemplarCity": "Atirau"
                },
                "Baghdad": {
                  "exemplarCity": "Bagdad"
                },
                "Bahrain": {
                  "exemplarCity": "Baréin"
                },
                "Baku": {
                  "exemplarCity": "Bakú"
                },
                "Bangkok": {
                  "exemplarCity": "Bangkok"
                },
                "Barnaul": {
                  "exemplarCity": "Barnaúl"
                },
                "Beirut": {
                  "exemplarCity": "Beirut"
                },
                "Bishkek": {
                  "exemplarCity": "Bishkek"
                },
                "Brunei": {
                  "exemplarCity": "Brunéi"
                },
                "Calcutta": {
                  "exemplarCity": "Calcuta"
                },
                "Chita": {
                  "exemplarCity": "Chitá"
                },
                "Choibalsan": {
                  "exemplarCity": "Choibalsan"
                },
                "Colombo": {
                  "exemplarCity": "Colombo"
                },
                "Damascus": {
                  "exemplarCity": "Damasco"
                },
                "Dhaka": {
                  "exemplarCity": "Daca"
                },
                "Dili": {
                  "exemplarCity": "Dili"
                },
                "Dubai": {
                  "exemplarCity": "Dubái"
                },
                "Dushanbe": {
                  "exemplarCity": "Dusambé"
                },
                "Famagusta": {
                  "exemplarCity": "Famagusta"
                },
                "Gaza": {
                  "exemplarCity": "Gaza"
                },
                "Hebron": {
                  "exemplarCity": "Hebrón"
                },
                "Hong_Kong": {
                  "exemplarCity": "Hong Kong"
                },
                "Hovd": {
                  "exemplarCity": "Hovd"
                },
                "Irkutsk": {
                  "exemplarCity": "Irkutsk"
                },
                "Jakarta": {
                  "exemplarCity": "Yakarta"
                },
                "Jayapura": {
                  "exemplarCity": "Jayapura"
                },
                "Jerusalem": {
                  "exemplarCity": "Jerusalén"
                },
                "Kabul": {
                  "exemplarCity": "Kabul"
                },
                "Kamchatka": {
                  "exemplarCity": "Kamchatka"
                },
                "Karachi": {
                  "exemplarCity": "Karachi"
                },
                "Katmandu": {
                  "exemplarCity": "Katmandú"
                },
                "Khandyga": {
                  "exemplarCity": "Khandyga"
                },
                "Krasnoyarsk": {
                  "exemplarCity": "Krasnoyarsk"
                },
                "Kuala_Lumpur": {
                  "exemplarCity": "Kuala Lumpur"
                },
                "Kuching": {
                  "exemplarCity": "Kuching"
                },
                "Kuwait": {
                  "exemplarCity": "Kuwait"
                },
                "Macau": {
                  "exemplarCity": "Macao"
                },
                "Magadan": {
                  "exemplarCity": "Magadán"
                },
                "Makassar": {
                  "exemplarCity": "Makasar"
                },
                "Manila": {
                  "exemplarCity": "Manila"
                },
                "Muscat": {
                  "exemplarCity": "Mascate"
                },
                "Nicosia": {
                  "exemplarCity": "Nicosia"
                },
                "Novokuznetsk": {
                  "exemplarCity": "Novokuznetsk"
                },
                "Novosibirsk": {
                  "exemplarCity": "Novosibirsk"
                },
                "Omsk": {
                  "exemplarCity": "Omsk"
                },
                "Oral": {
                  "exemplarCity": "Oral"
                },
                "Phnom_Penh": {
                  "exemplarCity": "Phnom Penh"
                },
                "Pontianak": {
                  "exemplarCity": "Pontianak"
                },
                "Pyongyang": {
                  "exemplarCity": "Pyongyang"
                },
                "Qatar": {
                  "exemplarCity": "Catar"
                },
                "Qostanay": {
                  "exemplarCity": "Kostanái"
                },
                "Qyzylorda": {
                  "exemplarCity": "Kyzylorda"
                },
                "Rangoon": {
                  "exemplarCity": "Yangón (Rangún)"
                },
                "Riyadh": {
                  "exemplarCity": "Riad"
                },
                "Saigon": {
                  "exemplarCity": "Ciudad Ho Chi Minh"
                },
                "Sakhalin": {
                  "exemplarCity": "Sajalín"
                },
                "Samarkand": {
                  "exemplarCity": "Samarcanda"
                },
                "Seoul": {
                  "exemplarCity": "Seúl"
                },
                "Shanghai": {
                  "exemplarCity": "Shanghái"
                },
                "Singapore": {
                  "exemplarCity": "Singapur"
                },
                "Srednekolymsk": {
                  "exemplarCity": "Srednekolimsk"
                },
                "Taipei": {
                  "exemplarCity": "Taipéi"
                },
                "Tashkent": {
                  "exemplarCity": "Taskent"
                },
                "Tbilisi": {
                  "exemplarCity": "Tiflis"
                },
                "Tehran": {
                  "exemplarCity": "Teherán"
                },
                "Thimphu": {
                  "exemplarCity": "Timbu"
                },
                "Tokyo": {
                  "exemplarCity": "Tokio"
                },
                "Tomsk": {
                  "exemplarCity": "Tomsk"
                },
                "Ulaanbaatar": {
                  "exemplarCity": "Ulán Bator"
                },
                "Urumqi": {
                  "exemplarCity": "Ürümqi"
                },
                "Ust-Nera": {
                  "exemplarCity": "Ust-Nera"
                },
                "Vientiane": {
                  "exemplarCity": "Vientián"
                },
                "Vladivostok": {
                  "exemplarCity": "Vladivostok"
                },
                "Yakutsk": {
                  "exemplarCity": "Yakutsk"
                },
                "Yekaterinburg": {
                  "exemplarCity": "Ekaterimburgo"
                },
                "Yerevan": {
                  "exemplarCity": "Ereván"
                }
              },
              "Indian": {
                "Antananarivo": {
                  "exemplarCity": "Antananarivo"
                },
                "Chagos": {
                  "exemplarCity": "Chagos"
                },
                "Christmas": {
                  "exemplarCity": "Navidad"
                },
                "Cocos": {
                  "exemplarCity": "Cocos"
                },
                "Comoro": {
                  "exemplarCity": "Comoras"
                },
                "Kerguelen": {
                  "exemplarCity": "Kerguelen"
                },
                "Mahe": {
                  "exemplarCity": "Mahé"
                },
                "Maldives": {
                  "exemplarCity": "Maldivas"
                },
                "Mauritius": {
                  "exemplarCity": "Mauricio"
                },
                "Mayotte": {
                  "exemplarCity": "Mayotte"
                },
                "Reunion": {
                  "exemplarCity": "Reunión"
                }
              },
              "Australia": {
                "Adelaide": {
                  "exemplarCity": "Adelaida"
                },
                "Brisbane": {
                  "exemplarCity": "Brisbane"
                },
                "Broken_Hill": {
                  "exemplarCity": "Broken Hill"
                },
                "Currie": {
                  "exemplarCity": "Currie"
                },
                "Darwin": {
                  "exemplarCity": "Darwin"
                },
                "Eucla": {
                  "exemplarCity": "Eucla"
                },
                "Hobart": {
                  "exemplarCity": "Hobart"
                },
                "Lindeman": {
                  "exemplarCity": "Lindeman"
                },
                "Lord_Howe": {
                  "exemplarCity": "Lord Howe"
                },
                "Melbourne": {
                  "exemplarCity": "Melbourne"
                },
                "Perth": {
                  "exemplarCity": "Perth"
                },
                "Sydney": {
                  "exemplarCity": "Sídney"
                }
              },
              "Pacific": {
                "Apia": {
                  "exemplarCity": "Apia"
                },
                "Auckland": {
                  "exemplarCity": "Auckland"
                },
                "Bougainville": {
                  "exemplarCity": "Bougainville"
                },
                "Chatham": {
                  "exemplarCity": "Chatham"
                },
                "Easter": {
                  "exemplarCity": "Isla de Pascua"
                },
                "Efate": {
                  "exemplarCity": "Efate"
                },
                "Enderbury": {
                  "exemplarCity": "Enderbury"
                },
                "Fakaofo": {
                  "exemplarCity": "Fakaofo"
                },
                "Fiji": {
                  "exemplarCity": "Fiyi"
                },
                "Funafuti": {
                  "exemplarCity": "Funafuti"
                },
                "Galapagos": {
                  "exemplarCity": "Galápagos"
                },
                "Gambier": {
                  "exemplarCity": "Gambier"
                },
                "Guadalcanal": {
                  "exemplarCity": "Guadalcanal"
                },
                "Guam": {
                  "exemplarCity": "Guam"
                },
                "Honolulu": {
                  "exemplarCity": "Honolulu"
                },
                "Johnston": {
                  "exemplarCity": "Johnston"
                },
                "Kiritimati": {
                  "exemplarCity": "Kiritimati"
                },
                "Kosrae": {
                  "exemplarCity": "Kosrae"
                },
                "Kwajalein": {
                  "exemplarCity": "Kwajalein"
                },
                "Majuro": {
                  "exemplarCity": "Majuro"
                },
                "Marquesas": {
                  "exemplarCity": "Marquesas"
                },
                "Midway": {
                  "exemplarCity": "Midway"
                },
                "Nauru": {
                  "exemplarCity": "Nauru"
                },
                "Niue": {
                  "exemplarCity": "Niue"
                },
                "Norfolk": {
                  "exemplarCity": "Norfolk"
                },
                "Noumea": {
                  "exemplarCity": "Numea"
                },
                "Pago_Pago": {
                  "exemplarCity": "Pago Pago"
                },
                "Palau": {
                  "exemplarCity": "Palaos"
                },
                "Pitcairn": {
                  "exemplarCity": "Pitcairn"
                },
                "Ponape": {
                  "exemplarCity": "Pohnpei"
                },
                "Port_Moresby": {
                  "exemplarCity": "Port Moresby"
                },
                "Rarotonga": {
                  "exemplarCity": "Rarotonga"
                },
                "Saipan": {
                  "exemplarCity": "Saipán"
                },
                "Tahiti": {
                  "exemplarCity": "Tahití"
                },
                "Tarawa": {
                  "exemplarCity": "Tarawa"
                },
                "Tongatapu": {
                  "exemplarCity": "Tongatapu"
                },
                "Truk": {
                  "exemplarCity": "Chuuk"
                },
                "Wake": {
                  "exemplarCity": "Wake"
                },
                "Wallis": {
                  "exemplarCity": "Wallis"
                }
              },
              "Arctic": {
                "Longyearbyen": {
                  "exemplarCity": "Longyearbyen"
                }
              },
              "Antarctica": {
                "Casey": {
                  "exemplarCity": "Casey"
                },
                "Davis": {
                  "exemplarCity": "Davis"
                },
                "DumontDUrville": {
                  "exemplarCity": "Dumont d’Urville"
                },
                "Macquarie": {
                  "exemplarCity": "Macquarie"
                },
                "Mawson": {
                  "exemplarCity": "Mawson"
                },
                "McMurdo": {
                  "exemplarCity": "McMurdo"
                },
                "Palmer": {
                  "exemplarCity": "Palmer"
                },
                "Rothera": {
                  "exemplarCity": "Rothera"
                },
                "Syowa": {
                  "exemplarCity": "Syowa"
                },
                "Troll": {
                  "exemplarCity": "Troll"
                },
                "Vostok": {
                  "exemplarCity": "Vostok"
                }
              },
              "Etc": {
                "UTC": {
                  "long": {
                    "standard": "hora universal coordinada"
                  },
                  "short": {
                    "standard": "UTC"
                  }
                },
                "Unknown": {
                  "exemplarCity": "ciudad desconocida"
                }
              }
            },
            "metazone": {
              "Acre": {
                "long": {
                  "generic": "Hora de Acre",
                  "standard": "Hora estándar de Acre",
                  "daylight": "Hora de verano de Acre"
                }
              },
              "Afghanistan": {
                "long": {
                  "standard": "hora de Afganistán"
                }
              },
              "Africa_Central": {
                "long": {
                  "standard": "hora de África central"
                }
              },
              "Africa_Eastern": {
                "long": {
                  "standard": "hora de África oriental"
                }
              },
              "Africa_Southern": {
                "long": {
                  "standard": "hora de Sudáfrica"
                }
              },
              "Africa_Western": {
                "long": {
                  "generic": "hora de África occidental",
                  "standard": "hora estándar de África occidental",
                  "daylight": "hora de verano de África occidental"
                }
              },
              "Alaska": {
                "long": {
                  "generic": "hora de Alaska",
                  "standard": "hora estándar de Alaska",
                  "daylight": "hora de verano de Alaska"
                }
              },
              "Amazon": {
                "long": {
                  "generic": "hora del Amazonas",
                  "standard": "hora estándar del Amazonas",
                  "daylight": "hora de verano del Amazonas"
                }
              },
              "America_Central": {
                "long": {
                  "generic": "hora central",
                  "standard": "hora estándar central",
                  "daylight": "hora de verano central"
                }
              },
              "America_Eastern": {
                "long": {
                  "generic": "hora oriental",
                  "standard": "hora estándar oriental",
                  "daylight": "hora de verano oriental"
                }
              },
              "America_Mountain": {
                "long": {
                  "generic": "hora de la montaña",
                  "standard": "hora estándar de la montaña",
                  "daylight": "hora de verano de la montaña"
                }
              },
              "America_Pacific": {
                "long": {
                  "generic": "hora del Pacífico",
                  "standard": "hora estándar del Pacífico",
                  "daylight": "hora de verano del Pacífico"
                }
              },
              "Anadyr": {
                "long": {
                  "generic": "hora de Anadyr",
                  "standard": "hora estándar de Anadyr",
                  "daylight": "hora de verano de Anadyr"
                }
              },
              "Apia": {
                "long": {
                  "generic": "hora de Apia",
                  "standard": "hora estándar de Apia",
                  "daylight": "hora de verano de Apia"
                }
              },
              "Aqtau": {
                "long": {
                  "generic": "Hora de Aktau",
                  "standard": "Hora estándar de Aktau",
                  "daylight": "Hora de verano de Aktau"
                }
              },
              "Aqtobe": {
                "long": {
                  "generic": "Hora de Aktobe",
                  "standard": "Hora estándar de Aktobe",
                  "daylight": "Hora de verano de Aktobe"
                }
              },
              "Arabian": {
                "long": {
                  "generic": "hora de Arabia",
                  "standard": "hora estándar de Arabia",
                  "daylight": "hora de verano de Arabia"
                }
              },
              "Argentina": {
                "long": {
                  "generic": "hora de Argentina",
                  "standard": "hora estándar de Argentina",
                  "daylight": "hora de verano de Argentina"
                }
              },
              "Argentina_Western": {
                "long": {
                  "generic": "hora de Argentina occidental",
                  "standard": "hora estándar de Argentina occidental",
                  "daylight": "hora de verano de Argentina occidental"
                }
              },
              "Armenia": {
                "long": {
                  "generic": "hora de Armenia",
                  "standard": "hora estándar de Armenia",
                  "daylight": "hora de verano de Armenia"
                }
              },
              "Atlantic": {
                "long": {
                  "generic": "hora del Atlántico",
                  "standard": "hora estándar del Atlántico",
                  "daylight": "hora de verano del Atlántico"
                }
              },
              "Australia_Central": {
                "long": {
                  "generic": "hora de Australia central",
                  "standard": "hora estándar de Australia central",
                  "daylight": "hora de verano de Australia central"
                }
              },
              "Australia_CentralWestern": {
                "long": {
                  "generic": "hora de Australia centroccidental",
                  "standard": "hora estándar de Australia centroccidental",
                  "daylight": "hora de verano de Australia centroccidental"
                }
              },
              "Australia_Eastern": {
                "long": {
                  "generic": "hora de Australia oriental",
                  "standard": "hora estándar de Australia oriental",
                  "daylight": "hora de verano de Australia oriental"
                }
              },
              "Australia_Western": {
                "long": {
                  "generic": "hora de Australia occidental",
                  "standard": "hora estándar de Australia occidental",
                  "daylight": "hora de verano de Australia occidental"
                }
              },
              "Azerbaijan": {
                "long": {
                  "generic": "hora de Azerbaiyán",
                  "standard": "hora estándar de Azerbaiyán",
                  "daylight": "hora de verano de Azerbaiyán"
                }
              },
              "Azores": {
                "long": {
                  "generic": "hora de las Azores",
                  "standard": "hora estándar de las Azores",
                  "daylight": "hora de verano de las Azores"
                }
              },
              "Bangladesh": {
                "long": {
                  "generic": "hora de Bangladés",
                  "standard": "hora estándar de Bangladés",
                  "daylight": "hora de verano de Bangladés"
                }
              },
              "Bhutan": {
                "long": {
                  "standard": "hora de Bután"
                }
              },
              "Bolivia": {
                "long": {
                  "standard": "hora de Bolivia"
                }
              },
              "Brasilia": {
                "long": {
                  "generic": "hora de Brasilia",
                  "standard": "hora estándar de Brasilia",
                  "daylight": "hora de verano de Brasilia"
                }
              },
              "Brunei": {
                "long": {
                  "standard": "hora de Brunéi"
                }
              },
              "Cape_Verde": {
                "long": {
                  "generic": "hora de Cabo Verde",
                  "standard": "hora estándar de Cabo Verde",
                  "daylight": "hora de verano de Cabo Verde"
                }
              },
              "Chamorro": {
                "long": {
                  "standard": "hora estándar de Chamorro"
                }
              },
              "Chatham": {
                "long": {
                  "generic": "hora de Chatham",
                  "standard": "hora estándar de Chatham",
                  "daylight": "hora de verano de Chatham"
                }
              },
              "Chile": {
                "long": {
                  "generic": "hora de Chile",
                  "standard": "hora estándar de Chile",
                  "daylight": "hora de verano de Chile"
                }
              },
              "China": {
                "long": {
                  "generic": "hora de China",
                  "standard": "hora estándar de China",
                  "daylight": "hora de verano de China"
                }
              },
              "Choibalsan": {
                "long": {
                  "generic": "hora de Choibalsan",
                  "standard": "hora estándar de Choibalsan",
                  "daylight": "hora de verano de Choibalsan"
                }
              },
              "Christmas": {
                "long": {
                  "standard": "hora de la isla de Navidad"
                }
              },
              "Cocos": {
                "long": {
                  "standard": "hora de las Islas Cocos"
                }
              },
              "Colombia": {
                "long": {
                  "generic": "hora de Colombia",
                  "standard": "hora estándar de Colombia",
                  "daylight": "hora de verano de Colombia"
                }
              },
              "Cook": {
                "long": {
                  "generic": "hora de las Islas Cook",
                  "standard": "hora estándar de las Islas Cook",
                  "daylight": "hora de verano media de las Islas Cook"
                }
              },
              "Cuba": {
                "long": {
                  "generic": "hora de Cuba",
                  "standard": "hora estándar de Cuba",
                  "daylight": "hora de verano de Cuba"
                }
              },
              "Davis": {
                "long": {
                  "standard": "hora de Davis"
                }
              },
              "DumontDUrville": {
                "long": {
                  "standard": "hora de Dumont-d’Urville"
                }
              },
              "East_Timor": {
                "long": {
                  "standard": "hora de Timor Oriental"
                }
              },
              "Easter": {
                "long": {
                  "generic": "hora de Isla de Pascua",
                  "standard": "hora estándar de la isla de Pascua",
                  "daylight": "hora de verano de la isla de Pascua"
                }
              },
              "Ecuador": {
                "long": {
                  "standard": "hora de Ecuador"
                }
              },
              "Europe_Central": {
                "long": {
                  "generic": "hora de Europa central",
                  "standard": "hora estándar de Europa central",
                  "daylight": "hora de verano de Europa central"
                }
              },
              "Europe_Eastern": {
                "long": {
                  "generic": "hora de Europa oriental",
                  "standard": "hora estándar de Europa oriental",
                  "daylight": "hora de verano de Europa oriental"
                }
              },
              "Europe_Further_Eastern": {
                "long": {
                  "standard": "hora del extremo oriental de Europa"
                }
              },
              "Europe_Western": {
                "long": {
                  "generic": "hora de Europa occidental",
                  "standard": "hora estándar de Europa occidental",
                  "daylight": "hora de verano de Europa occidental"
                }
              },
              "Falkland": {
                "long": {
                  "generic": "hora de Islas Malvinas",
                  "standard": "hora estándar de Islas Malvinas",
                  "daylight": "hora de verano de Islas Malvinas"
                }
              },
              "Fiji": {
                "long": {
                  "generic": "hora de Fiyi",
                  "standard": "hora estándar de Fiyi",
                  "daylight": "hora de verano de Fiyi"
                }
              },
              "French_Guiana": {
                "long": {
                  "standard": "hora de la Guayana Francesa"
                }
              },
              "French_Southern": {
                "long": {
                  "standard": "hora de las Tierras Australes y Antárticas Francesas"
                }
              },
              "Galapagos": {
                "long": {
                  "standard": "hora de Galápagos"
                }
              },
              "Gambier": {
                "long": {
                  "standard": "hora de Gambier"
                }
              },
              "Georgia": {
                "long": {
                  "generic": "hora de Georgia",
                  "standard": "hora estándar de Georgia",
                  "daylight": "hora de verano de Georgia"
                }
              },
              "Gilbert_Islands": {
                "long": {
                  "standard": "hora de las Islas Gilbert"
                }
              },
              "GMT": {
                "long": {
                  "standard": "hora del meridiano de Greenwich"
                }
              },
              "Greenland_Eastern": {
                "long": {
                  "generic": "hora de Groenlandia oriental",
                  "standard": "hora estándar de Groenlandia oriental",
                  "daylight": "hora de verano de Groenlandia oriental"
                }
              },
              "Greenland_Western": {
                "long": {
                  "generic": "hora de Groenlandia occidental",
                  "standard": "hora estándar de Groenlandia occidental",
                  "daylight": "hora de verano de Groenlandia occidental"
                }
              },
              "Guam": {
                "long": {
                  "standard": "Hora estándar de Guam"
                }
              },
              "Gulf": {
                "long": {
                  "standard": "hora estándar del Golfo"
                }
              },
              "Guyana": {
                "long": {
                  "standard": "hora de Guyana"
                }
              },
              "Hawaii_Aleutian": {
                "long": {
                  "generic": "hora de Hawái-Aleutianas",
                  "standard": "hora estándar de Hawái-Aleutianas",
                  "daylight": "hora de verano de Hawái-Aleutianas"
                }
              },
              "Hong_Kong": {
                "long": {
                  "generic": "hora de Hong Kong",
                  "standard": "hora estándar de Hong Kong",
                  "daylight": "hora de verano de Hong Kong"
                }
              },
              "Hovd": {
                "long": {
                  "generic": "hora de Hovd",
                  "standard": "hora estándar de Hovd",
                  "daylight": "hora de verano de Hovd"
                }
              },
              "India": {
                "long": {
                  "standard": "hora de India"
                }
              },
              "Indian_Ocean": {
                "long": {
                  "standard": "hora del océano Índico"
                }
              },
              "Indochina": {
                "long": {
                  "standard": "hora de Indochina"
                }
              },
              "Indonesia_Central": {
                "long": {
                  "standard": "hora de Indonesia central"
                }
              },
              "Indonesia_Eastern": {
                "long": {
                  "standard": "hora de Indonesia oriental"
                }
              },
              "Indonesia_Western": {
                "long": {
                  "standard": "hora de Indonesia occidental"
                }
              },
              "Iran": {
                "long": {
                  "generic": "hora de Irán",
                  "standard": "hora estándar de Irán",
                  "daylight": "hora de verano de Irán"
                }
              },
              "Irkutsk": {
                "long": {
                  "generic": "hora de Irkutsk",
                  "standard": "hora estándar de Irkutsh",
                  "daylight": "hora de verano de Irkutsh"
                }
              },
              "Israel": {
                "long": {
                  "generic": "hora de Israel",
                  "standard": "hora estándar de Israel",
                  "daylight": "hora de verano de Israel"
                }
              },
              "Japan": {
                "long": {
                  "generic": "hora de Japón",
                  "standard": "hora estándar de Japón",
                  "daylight": "hora de verano de Japón"
                }
              },
              "Kamchatka": {
                "long": {
                  "generic": "hora de Kamchatka",
                  "standard": "hora estándar de Kamchatka",
                  "daylight": "hora de verano de Kamchatka"
                }
              },
              "Kazakhstan_Eastern": {
                "long": {
                  "standard": "hora de Kazajistán oriental"
                }
              },
              "Kazakhstan_Western": {
                "long": {
                  "standard": "hora de Kazajistán occidental"
                }
              },
              "Korea": {
                "long": {
                  "generic": "hora de Corea",
                  "standard": "hora estándar de Corea",
                  "daylight": "hora de verano de Corea"
                }
              },
              "Kosrae": {
                "long": {
                  "standard": "hora de Kosrae"
                }
              },
              "Krasnoyarsk": {
                "long": {
                  "generic": "hora de Krasnoyarsk",
                  "standard": "hora estándar de Krasnoyarsk",
                  "daylight": "hora de verano de Krasnoyarsk"
                }
              },
              "Kyrgystan": {
                "long": {
                  "standard": "hora de Kirguistán"
                }
              },
              "Lanka": {
                "long": {
                  "standard": "Hora de Sri Lanka"
                }
              },
              "Line_Islands": {
                "long": {
                  "standard": "hora de las Espóradas Ecuatoriales"
                }
              },
              "Lord_Howe": {
                "long": {
                  "generic": "hora de Lord Howe",
                  "standard": "hora estándar de Lord Howe",
                  "daylight": "hora de verano de Lord Howe"
                }
              },
              "Macau": {
                "long": {
                  "generic": "Hora de Macao",
                  "standard": "Hora estándar de Macao",
                  "daylight": "Hora de verano de Macao"
                }
              },
              "Macquarie": {
                "long": {
                  "standard": "hora de la isla Macquarie"
                }
              },
              "Magadan": {
                "long": {
                  "generic": "hora de Magadán",
                  "standard": "hora estándar de Magadán",
                  "daylight": "hora de verano de Magadán"
                }
              },
              "Malaysia": {
                "long": {
                  "standard": "hora de Malasia"
                }
              },
              "Maldives": {
                "long": {
                  "standard": "hora de Maldivas"
                }
              },
              "Marquesas": {
                "long": {
                  "standard": "hora de Marquesas"
                }
              },
              "Marshall_Islands": {
                "long": {
                  "standard": "hora de las Islas Marshall"
                }
              },
              "Mauritius": {
                "long": {
                  "generic": "hora de Mauricio",
                  "standard": "hora estándar de Mauricio",
                  "daylight": "hora de verano de Mauricio"
                }
              },
              "Mawson": {
                "long": {
                  "standard": "hora de Mawson"
                }
              },
              "Mexico_Northwest": {
                "long": {
                  "generic": "hora del noroeste de México",
                  "standard": "hora estándar del noroeste de México",
                  "daylight": "hora de verano del noroeste de México"
                }
              },
              "Mexico_Pacific": {
                "long": {
                  "generic": "hora del Pacífico de México",
                  "standard": "hora estándar del Pacífico de México",
                  "daylight": "hora de verano del Pacífico de México"
                }
              },
              "Mongolia": {
                "long": {
                  "generic": "hora de Ulán Bator",
                  "standard": "hora estándar de Ulán Bator",
                  "daylight": "hora de verano de Ulán Bator"
                }
              },
              "Moscow": {
                "long": {
                  "generic": "hora de Moscú",
                  "standard": "hora estándar de Moscú",
                  "daylight": "hora de verano de Moscú"
                }
              },
              "Myanmar": {
                "long": {
                  "standard": "hora de Myanmar (Birmania)"
                }
              },
              "Nauru": {
                "long": {
                  "standard": "hora de Nauru"
                }
              },
              "Nepal": {
                "long": {
                  "standard": "hora de Nepal"
                }
              },
              "New_Caledonia": {
                "long": {
                  "generic": "hora de Nueva Caledonia",
                  "standard": "hora estándar de Nueva Caledonia",
                  "daylight": "hora de verano de Nueva Caledonia"
                }
              },
              "New_Zealand": {
                "long": {
                  "generic": "hora de Nueva Zelanda",
                  "standard": "hora estándar de Nueva Zelanda",
                  "daylight": "hora de verano de Nueva Zelanda"
                }
              },
              "Newfoundland": {
                "long": {
                  "generic": "hora de Terranova",
                  "standard": "hora estándar de Terranova",
                  "daylight": "hora de verano de Terranova"
                }
              },
              "Niue": {
                "long": {
                  "standard": "hora de Niue"
                }
              },
              "Norfolk": {
                "long": {
                  "standard": "hora de la isla Norfolk"
                }
              },
              "Noronha": {
                "long": {
                  "generic": "hora de Fernando de Noronha",
                  "standard": "hora estándar de Fernando de Noronha",
                  "daylight": "hora de verano de Fernando de Noronha"
                }
              },
              "North_Mariana": {
                "long": {
                  "standard": "Hora de las Islas Marianas del Norte"
                }
              },
              "Novosibirsk": {
                "long": {
                  "generic": "hora de Novosibirsk",
                  "standard": "hora estándar de Novosibirsk",
                  "daylight": "hora de verano de Novosibirsk"
                }
              },
              "Omsk": {
                "long": {
                  "generic": "hora de Omsk",
                  "standard": "hora estándar de Omsk",
                  "daylight": "hora de verano de Omsk"
                }
              },
              "Pakistan": {
                "long": {
                  "generic": "hora de Pakistán",
                  "standard": "hora estándar de Pakistán",
                  "daylight": "hora de verano de Pakistán"
                }
              },
              "Palau": {
                "long": {
                  "standard": "hora de Palaos"
                }
              },
              "Papua_New_Guinea": {
                "long": {
                  "standard": "hora de Papúa Nueva Guinea"
                }
              },
              "Paraguay": {
                "long": {
                  "generic": "hora de Paraguay",
                  "standard": "hora estándar de Paraguay",
                  "daylight": "hora de verano de Paraguay"
                }
              },
              "Peru": {
                "long": {
                  "generic": "hora de Perú",
                  "standard": "hora estándar de Perú",
                  "daylight": "hora de verano de Perú"
                }
              },
              "Philippines": {
                "long": {
                  "generic": "hora de Filipinas",
                  "standard": "hora estándar de Filipinas",
                  "daylight": "hora de verano de Filipinas"
                }
              },
              "Phoenix_Islands": {
                "long": {
                  "standard": "hora de las Islas Fénix"
                }
              },
              "Pierre_Miquelon": {
                "long": {
                  "generic": "hora de San Pedro y Miquelón",
                  "standard": "hora estándar de San Pedro y Miquelón",
                  "daylight": "hora de verano de San Pedro y Miquelón"
                }
              },
              "Pitcairn": {
                "long": {
                  "standard": "hora de Pitcairn"
                }
              },
              "Ponape": {
                "long": {
                  "standard": "hora de Pohnpei"
                }
              },
              "Pyongyang": {
                "long": {
                  "standard": "hora de Pyongyang"
                }
              },
              "Qyzylorda": {
                "long": {
                  "generic": "Hora de Qyzylorda",
                  "standard": "Hora estándar de Qyzylorda",
                  "daylight": "Hora de verano de Qyzylorda"
                }
              },
              "Reunion": {
                "long": {
                  "standard": "hora de Reunión"
                }
              },
              "Rothera": {
                "long": {
                  "standard": "hora de Rothera"
                }
              },
              "Sakhalin": {
                "long": {
                  "generic": "hora de Sajalín",
                  "standard": "hora estándar de Sajalín",
                  "daylight": "hora de verano de Sajalín"
                }
              },
              "Samara": {
                "long": {
                  "generic": "hora de Samara",
                  "standard": "hora estándar de Samara",
                  "daylight": "hora de verano de Samara"
                }
              },
              "Samoa": {
                "long": {
                  "generic": "hora de Samoa",
                  "standard": "hora estándar de Samoa",
                  "daylight": "hora de verano de Samoa"
                }
              },
              "Seychelles": {
                "long": {
                  "standard": "hora de Seychelles"
                }
              },
              "Singapore": {
                "long": {
                  "standard": "hora de Singapur"
                }
              },
              "Solomon": {
                "long": {
                  "standard": "hora de las Islas Salomón"
                }
              },
              "South_Georgia": {
                "long": {
                  "standard": "hora de Georgia del Sur"
                }
              },
              "Suriname": {
                "long": {
                  "standard": "hora de Surinam"
                }
              },
              "Syowa": {
                "long": {
                  "standard": "hora de Syowa"
                }
              },
              "Tahiti": {
                "long": {
                  "standard": "hora de Tahití"
                }
              },
              "Taipei": {
                "long": {
                  "generic": "hora de Taipéi",
                  "standard": "hora estándar de Taipéi",
                  "daylight": "hora de verano de Taipéi"
                }
              },
              "Tajikistan": {
                "long": {
                  "standard": "hora de Tayikistán"
                }
              },
              "Tokelau": {
                "long": {
                  "standard": "hora de Tokelau"
                }
              },
              "Tonga": {
                "long": {
                  "generic": "hora de Tonga",
                  "standard": "hora estándar de Tonga",
                  "daylight": "hora de verano de Tonga"
                }
              },
              "Truk": {
                "long": {
                  "standard": "hora de Chuuk"
                }
              },
              "Turkmenistan": {
                "long": {
                  "generic": "hora de Turkmenistán",
                  "standard": "hora estándar de Turkmenistán",
                  "daylight": "hora de verano de Turkmenistán"
                }
              },
              "Tuvalu": {
                "long": {
                  "standard": "hora de Tuvalu"
                }
              },
              "Uruguay": {
                "long": {
                  "generic": "hora de Uruguay",
                  "standard": "hora estándar de Uruguay",
                  "daylight": "hora de verano de Uruguay"
                }
              },
              "Uzbekistan": {
                "long": {
                  "generic": "hora de Uzbekistán",
                  "standard": "hora estándar de Uzbekistán",
                  "daylight": "hora de verano de Uzbekistán"
                }
              },
              "Vanuatu": {
                "long": {
                  "generic": "hora de Vanuatu",
                  "standard": "hora estándar de Vanuatu",
                  "daylight": "hora de verano de Vanuatu"
                }
              },
              "Venezuela": {
                "long": {
                  "standard": "hora de Venezuela"
                }
              },
              "Vladivostok": {
                "long": {
                  "generic": "hora de Vladivostok",
                  "standard": "hora estándar de Vladivostok",
                  "daylight": "hora de verano de Vladivostok"
                }
              },
              "Volgograd": {
                "long": {
                  "generic": "hora de Volgogrado",
                  "standard": "hora estándar de Volgogrado",
                  "daylight": "hora de verano de Volgogrado"
                }
              },
              "Vostok": {
                "long": {
                  "standard": "hora de Vostok"
                }
              },
              "Wake": {
                "long": {
                  "standard": "hora de la Isla Wake"
                }
              },
              "Wallis": {
                "long": {
                  "standard": "hora de Wallis y Futuna"
                }
              },
              "Yakutsk": {
                "long": {
                  "generic": "hora de Yakutsk",
                  "standard": "hora estándar de Yakutsk",
                  "daylight": "hora de verano de Yakutsk"
                }
              },
              "Yekaterinburg": {
                "long": {
                  "generic": "hora de Ekaterimburgo",
                  "standard": "hora estándar de Ekaterimburgo",
                  "daylight": "hora de verano de Ekaterimburgo"
                }
              }
            }
          }
        }
      }
    }
};
  