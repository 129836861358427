import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar-multi',
  templateUrl: './navbar-multi.component.html',
  styleUrls: ['./navbar-multi.component.css']
})
export class NavbarMultiComponent implements OnInit {
	constructor(
    private router: Router
  ) { }

  navbarOpen:boolean = false;
  site:string;

  ngOnInit() {
    var url= this.router.url;
    console.log(this.router.url);
    

    if(url.includes('3d-evolution')){
      this.site = '3d-evolution';
      document.getElementById('silva-ev-logo').hidden=true;
      document.getElementById('lab-silva-logo').hidden=true;
      document.getElementById('productos').hidden=true;
      document.getElementById('productos-modal').hidden=true;
      document.getElementById('productosDot').hidden=true;
      //document.getElementById('citas').hidden=true;
      //document.getElementById('citas-modal').hidden=true;
      //document.getElementById('citasDot').hidden=true;
      //document.getElementById('pago').hidden=true;
      //document.getElementById('pago-modal').hidden=true;
      //document.getElementById('pagoDot').hidden=true;
      document.getElementById('promociones').hidden=true;
      document.getElementById('promociones-modal').hidden=true;
      document.getElementById('promocionesDot').hidden=true;
    }else if(url.includes('silva-evolucion')){
      this.site = 'silva-evolucion';
      document.getElementById('threeD-ev-logo').hidden=true;
      document.getElementById('lab-silva-logo').hidden=true;
      document.getElementById('servicios').hidden=true;
      document.getElementById('servicios-modal').hidden=true;
      document.getElementById('productosDot').hidden=true;
      document.getElementById('citas').hidden=true;
      document.getElementById('citas-modal').hidden=true;
      document.getElementById('citasDot').hidden=true;
      document.getElementById('pago').hidden=true;
      document.getElementById('pago-modal').hidden=true;
      document.getElementById('pagoDot').hidden=true;
      document.getElementById('descargas').hidden=true;
      document.getElementById('descargas-modal').hidden=true;
      document.getElementById('descargasDot').hidden=true;

      document.getElementById('productos').hidden=true;
      document.getElementById('promocionesDot').hidden=true;

    }else{
      this.site = 'lab-silva';
      document.getElementById('threeD-ev-logo').hidden=true;
      document.getElementById('silva-ev-logo').hidden=true;
      document.getElementById('productos').hidden=true;
      document.getElementById('productos-modal').hidden=true;
      document.getElementById('productosDot').hidden=true;
      document.getElementById('citas').hidden=true;
      document.getElementById('citas-modal').hidden=true;
      document.getElementById('citasDot').hidden=true;
      document.getElementById('pago').hidden=true;
      document.getElementById('pago-modal').hidden=true;
      document.getElementById('pagoDot').hidden=true;
      document.getElementById('descargas').hidden=true;
      document.getElementById('descargas-modal').hidden=true;
      document.getElementById('descargasDot').hidden=true;
    }
    console.log(this.site);

    var temp= url.split('/');
    document.getElementById(temp[temp.length-1]).classList.add('active');

  }

}
