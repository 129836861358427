import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'domseguro'
})
export class DomseguroPipe implements PipeTransform {

  constructor( private domSanatizer:DomSanitizer ){ }
    transform(url: string, place: string): SafeResourceUrl {
    return this.domSanatizer.bypassSecurityTrustResourceUrl( url + place );
  }

}
