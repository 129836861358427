import { Component, OnInit } from '@angular/core';
import { Descargable } from './descargable';
import { ServiceService } from '../data/services/service.service'
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { DownloadService } from '../download.service';
import { saveAs } from 'file-saver';
import { EventSettingsModel, View, WorkHoursModel, PopupOpenEventArgs } from '@syncfusion/ej2-angular-schedule';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-descargas',
  templateUrl: './descargas.component.html',
  styleUrls: ['./descargas.component.css']
})
export class DescargasComponent implements OnInit {
  downloadForm: FormGroup;
  
  ticket: FormControl;
  phone: FormControl;
  
  descargables: Descargable[]= [
    {nombre: 'Visor Romexies', descripcion: 'Visor para Windows', location: 'Romexis_Viewer_Win.zip'},
    {nombre: 'Visor Romexies', descripcion: 'Visor para MacOS', location: 'Romexis_Viewer_OS_X.app.zip'},
    { nombre: 'Dental Imagining Software Suite', descripcion: 'DIS Windows', location: 'Software_CS.zip'},

  ];

  constructor(private service: ServiceService, private downloadService: DownloadService,private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.ticket = new FormControl('', [
      Validators.required,
      Validators.pattern('^\\d*')
    ]);
    this.phone = new FormControl('', [
      Validators.required,
      Validators.pattern('^\\d*')
    ]);
  }
  createForm() {
    this.downloadForm = new FormGroup({
      ticket: this.ticket,
      phone: this.phone
    });
  }
  onSubmit(){
    if (this.downloadForm.valid) {
      const params = {
        folio: this.ticket.value,
        phone: this.phone.value
      };
      this.http.get<any>(environment.apiUrl+"/orders/files/search", {params: params}).subscribe(res=>{
        console.log(res);
        if(res.message){
          // Show message
        }else{
          const blob = new Blob([new Uint8Array(res.content.data)]);
          saveAs(blob, res.filename);
          this.downloadForm.reset();
        }
      });
    }
  }
  showAlert(data){
    alert("UserID: " + data.userId +"\n"+"Titulo: " + data.title);
  }

  downloadFile(location: string, filename: string){
    this.downloadService.getFile(location).subscribe(response => {
      const blob: any = new Blob([response.blob()], { type: 'blob' });
      saveAs(blob, filename);
    });
  }

}
