import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
// SmoothScroll
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
//slider
import { SlideshowModule } from 'ng-simple-slideshow';
//
/* Toaster */
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
/* END Toaster */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiciosComponent } from './servicios/servicios.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ContactoComponent } from './contacto/contacto.component';
import { InicioComponent } from './inicio/inicio.component';
import {
    ReactiveFormsModule,
    FormsModule,
    FormGroup,
    FormControl,
    Validators,
    FormBuilder,
} from '@angular/forms';
import { DescargasComponent } from './descargas/descargas.component';
import { PromocionesComponent } from './promociones/promociones.component';
import { InicioSilvaEvComponent } from './inicio-silva-ev/inicio-silva-ev.component';
import { ProductosSilvaEvComponent } from './productos-silva-ev/productos-silva-ev.component';
import { InicioLabSilvaComponent } from './inicio-lab-silva/inicio-lab-silva.component';
import { PromocionesLabSilvaComponent } from './promociones-lab-silva/promociones-lab-silva.component';
import { LandingComponent } from './landing/landing.component';
import { ServiciosLabSilvaComponent } from './servicios-lab-silva/servicios-lab-silva.component';
import { NavbarMultiComponent } from './navbar-multi/navbar-multi.component';
import { DomseguroPipe } from './pipes/domseguro.pipe';
import { DownloadService } from './download.service';

import { HttpModule } from '@angular/http';

import {ScheduleModule, DayService, WeekService, WorkWeekService, MonthService } from '@syncfusion/ej2-angular-schedule';
import { DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';

import { PagoTdEvComponent } from './pago-td-ev/pago-td-ev.component';
import { PagoComponent } from './pago/pago.component';
import { CitasTdEvComponent } from './citas-td-ev/citas-td-ev.component';


import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTreeModule} from '@angular/material/tree';

@NgModule({
  declarations: [
    AppComponent,
    ServiciosComponent,
    FooterComponent,
    NavbarComponent,
    ContactoComponent,
    InicioComponent,
    DescargasComponent,
    PromocionesComponent,
    InicioSilvaEvComponent,
    ProductosSilvaEvComponent,
    InicioLabSilvaComponent,
    PromocionesLabSilvaComponent,
    LandingComponent,
    ServiciosLabSilvaComponent,
    NavbarMultiComponent,
    DomseguroPipe,
    PagoTdEvComponent,
    PagoComponent,
    CitasTdEvComponent
  ],
  imports: [
    ScrollToModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    SlideshowModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    HttpModule,
    
    ScheduleModule,
    DateTimePickerModule,

    MatTreeModule,
    MatIconModule,
    MatCheckboxModule
  ],
  providers: [DayService, WeekService, WorkWeekService, MonthService],
  bootstrap: [AppComponent]
})
export class AppModule { }