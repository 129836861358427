import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  constructor() { }

 /* @HostListener("window:resize", ["$event"])
  private onResize(event) {
    var responsive = event.target.innerWidth;
    if(responsive > 600) {
      var element = document.getElementById("size");
      if (element) {
        
      }
    }
    else {
      var element = document.getElementById("size");
      if (element) {
        element.classList.remove("h-100");
        
      }
    }

    console.log("Width: " + event.target.innerWidth);
  } */


  ngOnInit() {
    var innerWidth = window.innerWidth;
    if(innerWidth < 601) {
      var element = document.getElementById("size1");
      if (element) {
        element.classList.remove("h-100");
        
      }
      element = document.getElementById("size2");
      if (element) {
        element.classList.remove("h-100");
        
      }
      element = document.getElementById("size3");
      if (element) {
        element.classList.remove("h-100");
        
      }
      document.getElementById("description1").innerHTML = '';
      document.getElementById("description2").innerHTML = '';
      document.getElementById("description3").innerHTML = '';
    }
  }

}
