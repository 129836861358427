import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppointmentsService } from '../services/appointments.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

declare var $: any;
declare var Conekta: any; //Declaración de la variable para que no marque error

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.css']
})
export class PagoComponent implements OnInit {

  constructor(private appService: AppointmentsService, private router: Router) { }

  //Inicialización del descuento
  discount: any = {
    amount: 0,
    code: ""
  };

  //Definición de la estructura de orden
  order: any = {
    client: {
      name : ""
    },
    date: ""
  };

  //Inicialización de una fecha
  date: Date = new Date(Date.now());

  //Definición de la estructura del formulario de orden
  orderForm: FormGroup = new FormGroup({
    folio: new FormControl('', Validators.required)
  });

  //Definición de la estructura del formulario de tarjeta
  cardForm: FormGroup = new FormGroup({
    code: new FormControl(''),
    name: new FormControl('', Validators.required),
    number: new FormControl('', Validators.required),
    exp_year: new FormControl('', Validators.required),
    exp_month: new FormControl('', Validators.required),
    cvc: new FormControl('', Validators.required)
  });

  ngOnInit() {
    Conekta.setPublicKey("key_bFcpuBuUNWPncKJBRbEMF3g"); //Se asigna la clave pública para Conekta
  }

  click(){
    //Llamada al servicio para obtener la orden ingresada
    this.appService.getAppointment(this.orderForm.value["folio"]).subscribe(
      (response:any) => {
        console.log(response);

        if(response.paid) {
          alert("Ya se encuentra pagada la cita");
          return;
        }

        //Se verifica si hubo resultado, en caso de que no mostrar un alert de que no se encontro
        if(typeof(response.id) != 'undefined'){ 
          this.order = response;
          this.date = new Date(this.order.start);
          this.order.total = 0;

          $('#collapseTwo').collapse('show');

          const cefalometricos = [30, 31, 32, 33, 34, 35];

          let arr = [];
          for(let i = 0; i < cefalometricos.length; i++)
            arr[cefalometricos[i]] = 0;

          this.order.studies.forEach(study => {
            switch(study.type.id) {
              default: 
                this.order.total += study.type.price;
                break;

              // Cefalometricos
              case 30:
              case 31:
              case 32:
              case 33:
              case 34:
              case 35:
                arr[study.type.id] += study.quantity;
                break;
            }
          });

          let max = 0;
          for(let i = 0; i < cefalometricos.length; i++) {
            if(arr[cefalometricos[i]] > max)
              max = arr[cefalometricos[i]];
          }

          this.order.total += max * 150;
        }
        else {
          alert("No existe una orden pendiente por pagar asociada a ese número de folio");
        }
      },
      error => {
        console.log(error); 
      }
    );
  }

  click2(){
    $('#collapseThree').collapse('show');
  }

  click3(){
    //Se hacen las validaciones necesarias antes de generar el token
    if(Conekta.card.validateNumber(this.cardForm.value['number']) 
      && Conekta.card.validateExpirationDate(this.cardForm.value['exp_month'], this.cardForm.value['exp_year'])
      && Conekta.card.validateCVC(this.cardForm.value['cvc'])){
        
        //Creación del token
        Conekta.Token.create({"card": this.cardForm.value}, (token:any) => {
          this.appService.pay(token.id, this.order, this.discount).subscribe( //Se llama al servicio de pago enviando el token
            response => {
              alert("El pago fue realizado con éxito"); //Si hay response es porque el pago funciono y sale este anuncio
              this.appService.sendReceipt(response, this.orderForm.value["folio"]).subscribe(
                response => {
                  this.router.navigate(['/3d-evolution/inicio']);
                },
                error => {
                  alert("No se pudo enviar el recibo a tu correo")
                }
              )
              this.router.navigate(['/3d-evolution/inicio']);
            },
            error => {
              alert("No se ha podido hacer el pago, intenta más tarde");
            }
          );
        })
    }
    else {
      alert('Los datos introducidos no son válidos');
    }
  }

  //Petición para cuando se ingresa número de descuento
  codeChange(){
    this.appService.getVoucher(this.cardForm.value['code']).subscribe(
      (response:any) => {
        //Si no hay un código de descuento, el desceutno se queda en 0
        if (response.status == "error") {
          this.discount.amount = 0;
          alert("No existe ese código de descuento");
        }
        //Si hay un código de descuento entra aqui
        else {
          let voucher = response.message;
          //Si el tipo de descuento es porcentaje entonces se restará ese porcentaje del total
          if (voucher.voucher_type == "percentage") {
            this.discount.code = voucher.code;
            this.discount.amount = this.order.total * (voucher.voucher_discount/100);
          }
          //Si el tipo de descuento es cantidad esa cantida será restada
          else {
            this.discount.code = voucher.code;
            this.discount.amount = voucher.voucher_discount;
          }
          alert("Se ha aplicado un descuento de $"+this.discount.amount);
        }
      }
    );
  }

}
