import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promociones-lab-silva',
  templateUrl: './promociones-lab-silva.component.html',
  styleUrls: ['./promociones-lab-silva.component.css']
})
export class PromocionesLabSilvaComponent implements OnInit {

  promociones : Promocion[] = [
    { nombre : "Promo 1", descripcion : "Descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve"},
    { nombre : "Promo 2", descripcion : "Descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve"},
    { nombre : "Promo 3", descripcion : "Descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve descripcion breve"}
  ];

  constructor() { }

  ngOnInit() {
  }

}
class Promocion {
  nombre: string;
  descripcion: string;
}